import { useEffect } from "react";
import { useAppDispatch } from "../hooks";
import moment from "moment-timezone";

import { doRefreshAll } from "../../redux/slices/thunks/refresh-helper";

function Refresh() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Init fetch
    dispatch(doRefreshAll());

    const worker = setInterval(async () => {
      const now = moment().tz("America/Chicago");
      var beforeTime = moment().tz("America/Chicago").startOf("day").add(8, "hours");
      var afterTime = moment().tz("America/Chicago").startOf("day").add(17, "hours");

      // Only auto refresh every hour between 8-5. So it doesn't kill the sheety limits.
      if (now.isBetween(beforeTime, afterTime)) {
        console.log("Is between active hours. Refetching...");
        console.log("Refetching...");

        dispatch(doRefreshAll());
      } else {
        console.log("Is not between. Do nothing.");
      }
    }, 1000 * 60 * 30); // 30 mins

    return () => {
      clearInterval(worker);
    };
  }, []);

  return <></>;
}

export default Refresh;
