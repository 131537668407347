import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "flowbite-react";

import { useAppSelector } from "../hooks";
import ColorModeSwitcher from "../components/header/ColorModeSwitcher";
import ManualRefreshButton from "../components/header/ManualRefreshButton";

const NavLink = ({ name, link }: { name: string; link: string }) => {
  return (
    <Link to={link}>
      <div className="block py-2 pl-3 pr-4 text-gray-700 rounded sm:mt-6 md:mt-0 md:text-sm lg:text-base hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
        {name}
      </div>
    </Link>
  );
};

const NavContent = () => {
  return (
    <>
      <div className="flex w-full md:block md:w-full" id="navbar-default">
        <ul className="flex justify-between w-full border border-gray-100 rounded-lg md:flex-row md:font-medium md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
          {[
            { name: "Batches", link: "/batches" },
            {
              name: "FNSKU/ Product Labels",
              link: "/fnsku-product-label",
            },
            { name: "Box Label Generator", link: "/label-generator" },
            {
              name: "Production Pallet Labels",
              link: "/production-pallets-generator",
            },
            {
              name: "Outbound Pallet Labels",
              link: "/outbound-pallets-generator",
            },
            {
              name: "PRO Pallet Labels",
              link: "/pro-pallets-generator",
            },
          ].map((x) => (
            <li key={x.link} className="flex items-center justify-center px-2 py-2 mr-4">
              <NavLink name={x.name} link={x.link} />
            </li>
          ))}
          <li>
            <Dropdown dismissOnClick label="More" size={"md"} style={{ width: "150px", height: "35px", margin: "0" }}>
              {[
                { name: "Shipment Data", link: "/shipments" },
                { name: "Products", link: "/products" },
                { name: "Liquidation", link: "/liquidation" },
                { name: "Production Hold", link: "/productionhold" },
              ].map((x) => (
                <li key={x.link} className="flex items-center justify-center px-2 py-2" style={{ width: "150px" }}>
                  <NavLink name={x.name} link={x.link} />
                </li>
              ))}
            </Dropdown>
          </li>
        </ul>
      </div>
    </>
  );
};

type NavType = {
  signOut: () => void;
};

const Header = ({ signOut }: NavType) => {
  const user = useAppSelector((state) => state.user.loggedInUser);
  return (
    <nav className="border-b-2 border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
      <div className="flex flex-wrap items-center justify-between mx-auto">
        <div className="flex items-center justify-between w-full mb-5">
          <Link to="/">
            <div className="flex justify-center gap-3">
              <img src="/images/logo.png" alt="logo" className="w-12" />
              <span className="self-center text-lg font-semibold whitespace-nowrap dark:text-white">Label App</span>
            </div>
          </Link>
          <div className="flex items-center justify-center invisible gap-2 md:visible">
            <img alt="pic" src={user?.picture} className="w-8 rounded-full" />
            <p className="hidden base-p lg:block">Welcome, {user?.name}!</p>
            <ManualRefreshButton />
            <ColorModeSwitcher />
            <button type="button" onClick={signOut} className="ml-3 p-2.5 base-gray-button">
              Sign Out
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between w-full">
          <NavContent />
        </div>
      </div>
    </nav>
  );
};

export default Header;
