import axios from "axios";

import { Batch, Product, Shipment } from "../../app/data";
import { apiBaseUrl, apiKey, batchSheetyUrl, productSheetyUrl, shipmentSheetyUrl } from "../../app/options";

export const headers = {
  "Content-Type": "application/json",
  "x-api-key": apiKey,
};

export const getPdf = async (url: string, data: any) => {
  try {
    const d = await (await axios.post(`${apiBaseUrl}/${url}`, data, { headers })).data;
    return d;
  } catch (err) {
    console.log(err);
  }
};

export const saveShipment = async (data: Shipment) => {
  try {
    const d = await (await axios.post(`${apiBaseUrl}/shipments`, data, { headers })).data;
    return d;
  } catch (err) {
    console.log(err);
  }
};

export const saveItem = async (data: Product) => {
  try {
    const d = await (await axios.post(`${apiBaseUrl}/products`, data, { headers })).data;
    return d;
  } catch (err) {
    console.log(err);
  }
};

export const updateBatches = async (data: { newBatches: Batch[]; updatedBatches: Batch[]; removedBatches: Batch[] }) => {
  try {
    const d = await (await axios.post(`${apiBaseUrl}/batches`, data, { headers })).data;
    return d;
  } catch (err) {
    console.log(err);
  }
};

export const upsertShipments = async (data: { newShipments: Shipment[]; updatedShipments: Shipment[] }) => {
  try {
    const d = await (await axios.post(`${apiBaseUrl}/upsertShipments`, data, { headers })).data;
    return d;
  } catch (err) {
    console.log(err);
  }
};

export const upsertProducts = async (data: { newProducts: Product[]; updatedProducts: Product[] }) => {
  try {
    const d = await (await axios.post(`${apiBaseUrl}/upsertProducts`, data, { headers })).data;
    return d;
  } catch (err) {
    console.log(err);
  }
};

export const getGoogleSheetBatches = async () => {
  const batches = await (await axios.get(batchSheetyUrl)).data;
  return batches;
};

export const getGoogleSheetProducts = async () => {
  const batches = await (await axios.get(productSheetyUrl)).data;
  return batches;
};

export const getGoogleSheetShipments = async () => {
  const batches = await (await axios.get(shipmentSheetyUrl)).data;
  return batches;
};

export const getShipments = async (): Promise<Shipment[]> => {
  const shipments = await (await axios.get(`${apiBaseUrl}/shipments`, { headers })).data;
  return shipments;
};
export const getBatches = async () => {
  const batches = await (await axios.get(`${apiBaseUrl}/batches`, { headers })).data;
  return batches;
};
export const getProducts = async () => {
  const products = await (await axios.get(`${apiBaseUrl}/products`, { headers })).data;
  return products;
};
