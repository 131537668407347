// Could make this better
export const areProductsEqual = (dbEntity: any, item: any): boolean => {
  const propsToCheck = ["name", "fn_sku", "image"];

  let areEqual = true;
  propsToCheck.forEach((prop) => {
    if (dbEntity[prop] !== item[prop]) {
      log(prop, dbEntity[prop], item[prop]);
      areEqual = false;
    }
  });

  return areEqual;
};

const log = (propName: string, fromValue: any, toValue: any) => {
  console.log(`${propName} ${fromValue} is not equal to ${toValue}`);
};
