const TablePageButtons = ({ table }: { table: any }) => {
  return (
    <div className="flex items-center gap-1 p-2 mt-2">
      <button
        className={`base-blue-button ${!table.getCanPreviousPage() ? 'cursor-not-allowed disabled' : ''}`}
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        {'<<'}
      </button>
      <button
        className={`base-blue-button ${!table.getCanPreviousPage() ? 'cursor-not-allowed disabled' : ''}`}
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        {'<'}
      </button>
      <button
        className={`base-blue-button ${!table.getCanNextPage() ? 'cursor-not-allowed disabled' : ''}`}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        {'>'}
      </button>
      <button
        className={`base-blue-button ${!table.getCanNextPage() ? 'cursor-not-allowed disabled' : ''}`}
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        {'>>'}
      </button>
      <span className="flex items-center gap-1 ml-5 base-p">
        <div>Page</div>
        <strong>
          {table.getState().pagination.pageIndex + 1} of{' '}
          {table.getPageCount()}
        </strong>
      </span>
    </div>
  )
}

export default TablePageButtons