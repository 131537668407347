import React, { ReactNode } from "react";

function Card({
  children,
  headerText,
  className,
}: {
  children: any;
  headerText?: string;
  className?: string;
}) {
  return (
    <div
      className={`flex flex-col flex-1 w-full base-card base-border pb-5 ${
        className ? className : ""
      }`}
    >
      {headerText && (
        <div className="block w-full px-6 py-2 text-white bg-red-600 rounded-t-lg">
          {headerText}
        </div>
      )}
      <div className="flex-1 block w-full px-6 py-3">{children}</div>
    </div>
  );
}

export default Card;
