import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Batch, Product, Shipment } from "../../app/data";

export interface InitState {
  isInitialLoad: boolean;
  isLoading: boolean;

  shipmentsInfo: string;
  shipmentsLastUpdatedTime: string;
  shipmentsCanPullGoogleSheet: boolean;
  shipments: Shipment[];

  productsInfo: string;
  productsLastUpdatedTime: string;
  productsCanPullGoogleSheet: boolean;
  products: Product[];

  batchAndProductsInfo: string;
  batchAndProductsLastUpdatedTime: string;
  batchAndProductsCanPullGoogleSheet: boolean;
  batches: Batch[];
}

const initialState: InitState = {
  isInitialLoad: true,
  isLoading: false,

  shipmentsInfo: "",
  shipmentsLastUpdatedTime: "",
  shipmentsCanPullGoogleSheet: true,
  shipments: [],

  productsInfo: "",
  productsLastUpdatedTime: "",
  productsCanPullGoogleSheet: true,
  products: [],

  batchAndProductsInfo: "",
  batchAndProductsLastUpdatedTime: "",
  batchAndProductsCanPullGoogleSheet: true,
  batches: [],
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setIsInitialLoad: (state, action: PayloadAction<boolean>) => {
      state.isInitialLoad = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShipmentsInfo: (state, action: PayloadAction<string>) => {
      state.shipmentsInfo = action.payload;
    },
    setShipmentsCanPullGoogleSheet: (state, action: PayloadAction<boolean>) => {
      state.shipmentsCanPullGoogleSheet = action.payload;
    },
    setShipmentsLastUpdatedTime: (state, action: PayloadAction<string>) => {
      state.shipmentsLastUpdatedTime = action.payload;
    },
    setShipments: (state, action: PayloadAction<Shipment[]>) => {
      state.shipments = action.payload;
    },

    setProductsInfo: (state, action: PayloadAction<string>) => {
      state.productsInfo = action.payload;
    },
    setProductsCanPullGoogleSheet: (state, action: PayloadAction<boolean>) => {
      state.productsCanPullGoogleSheet = action.payload;
    },
    setProductsLastUpdatedTime: (state, action: PayloadAction<string>) => {
      state.productsLastUpdatedTime = action.payload;
    },
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },

    setBatchAndProductsInfo: (state, action: PayloadAction<string>) => {
      state.batchAndProductsInfo = action.payload;
    },
    setBatchAndProductsCanPullGoogleSheet: (state, action: PayloadAction<boolean>) => {
      state.batchAndProductsCanPullGoogleSheet = action.payload;
    },
    setBatchAndProductsLastUpdatedTime: (state, action: PayloadAction<string>) => {
      state.batchAndProductsLastUpdatedTime = action.payload;
    },
    setBatchAndProducts: (state, action: PayloadAction<{ batches: Batch[]; shipments: Shipment[]; products: Product[] }>) => {
      state.batches = action.payload.batches;
      state.products = action.payload.products;
      state.shipments = action.payload.shipments;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setIsInitialLoad,
  setIsLoading,
  setShipmentsInfo,
  setShipmentsCanPullGoogleSheet,
  setShipmentsLastUpdatedTime,
  setShipments,

  setProductsInfo,
  setProductsCanPullGoogleSheet,
  setProductsLastUpdatedTime,
  setProducts,

  setBatchAndProductsInfo,
  setBatchAndProductsCanPullGoogleSheet,
  setBatchAndProductsLastUpdatedTime,
  setBatchAndProducts,
} = dataSlice.actions;

export default dataSlice.reducer;
