import React, { useState } from "react";
import { toast } from "react-toastify";
import { getPdf } from "../api/app-api";

type printType = {
  apiUrl: string;
  data: any;
};

export default function usePdfDialogHook() {
  const [canGenerate, setCanGenerate] = useState(true);
  const [isPDFDialogOpen, setIsPDFDialogOpen] = useState(false);
  const [pdfBase64, setPdfBase64] = useState("");

  const print = async ({ apiUrl, data }: printType) => {
    toast.info("Generating label", { autoClose: false });
    setCanGenerate(false);

    try {
      const d = await getPdf(apiUrl, data);
      setPdfBase64(d.body);
      setIsPDFDialogOpen(true);
      toast.dismiss();
    } catch (err) {
      // Error handling
      toast.dismiss();
      toast.warn("Failed to generate. Please try again later.");
      console.log(err);
    }

    setCanGenerate(true);
  };

  return {
    canGenerate,
    setCanGenerate,
    isPDFDialogOpen,
    setIsPDFDialogOpen,
    pdfBase64,
    setPdfBase64,

    print,
  };
}
