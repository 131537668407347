import { createColumnHelper, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import Card from "../components/cards/Card";
import Loading from "../components/Loading";
import { Batch } from "../data";
import Dtable from "../components/table/Dtable";
import { refreshBatchesAndProducts } from "../../redux/slices/thunks/refresh-helper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";

function BatchesPage() {
  const dispatch = useDispatch<AppDispatch>();

  const batches = useSelector((x: RootState) => x.data.batches);
  const products = useSelector((x: RootState) => x.data.products);
  const shipments = useSelector((x: RootState) => x.data.shipments);

  const canPullGoogleSheet = useSelector((x: RootState) => x.data.batchAndProductsCanPullGoogleSheet);
  const info = useSelector((x: RootState) => x.data.batchAndProductsInfo);
  const lastUpdatedTime = useSelector((x: RootState) => x.data.batchAndProductsLastUpdatedTime);
  const isLoading = useSelector((x: RootState) => x.data.isLoading);

  const columnHelper = createColumnHelper<Batch>();
  const columns = [
    columnHelper.accessor((row) => row.batch_id, {
      id: "batch_id",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Batch ID</span>,
    }),
    columnHelper.accessor((row) => row.assigned_pl, {
      id: "assigned_pl",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Assigned PL</span>,
    }),
    columnHelper.accessor((row) => row.shipment_id, {
      id: "shipment_id",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Shipment ID</span>,
    }),
    columnHelper.accessor((row) => row.product_sku, {
      id: "product_id",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Product SKU</span>,
    }),
    columnHelper.accessor((row) => row.product_name, {
      id: "product_name",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Product Name</span>,
    }),
    columnHelper.accessor((row) => row.expiration_date, {
      id: "expiration_date",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Expiration Date</span>,
    }),
    columnHelper.accessor((row) => row.quantity_per_box, {
      id: "quantity_per_box",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Quantity Per Box</span>,
    }),
    columnHelper.accessor((row) => row.identical_boxes, {
      id: "identical_boxes",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Identical Boxes</span>,
    }),
    columnHelper.accessor((row) => row.print_fnsku_label, {
      id: "print_fnsku",
      cell: (info) => <div className="basic-cell">{info.getValue() === 1 ? "TRUE" : "FALSE"}</div>,
      header: () => <span>Print FNSKU On Label</span>,
    }),
    columnHelper.accessor((row) => row.identical_boxes, {
      id: "print",
      cell: (info) => (
        <div className="basic-cell">
          <Link target={"_blank"} to={`/batchid/${info.row.original.batch_id}`}>
            <button className="base-green-button">Print this</button>
          </Link>
        </div>
      ),
      header: () => <span></span>,
    }),
  ];

  const table = useReactTable({
    data: batches ?? [],
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="flex w-full">
      <Card headerText={`Batches`}>
        <div className="flex gap-4">
          <button
            disabled={!canPullGoogleSheet}
            className={(!canPullGoogleSheet ? "disabled cursor-not-allowed" : "") + " base-blue-button mb-3"}
            onClick={() => dispatch(refreshBatchesAndProducts())}
          >
            Refresh Batches from Google Sheet
          </button>
          <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
            <span className="font-medium">{info}</span>
          </div>
          <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
            <span className="font-medium">Last refreshed time (CST): {lastUpdatedTime}</span>
          </div>
        </div>
        {isLoading && <Loading />}
        {batches && shipments && products && <Dtable table={table} columns={columns} />}
      </Card>
    </div>
  );
}

export default BatchesPage;
