import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Header from './app/layout/Header';
import ProductLabelPage from './app/pages/ProductLabelPage';
import ProductsPage from './app/pages/ProductsPage';
import OutboundPalletsLabelPage from './app/pages/OutboundPalletsLabelPage';
import FnSkuLabelPage from './app/pages/fn-sku-label-page/FnSkuLabelPage';
import MfFnSkuLabelPage from './app/pages/fn-sku-label-page/MfFnSkuLabelPage';
import ProductionHoldPage from './app/pages/ProdutionHoldPage';
import PalletProCarrierLabelPage from './app/pages/PalletProCarrierLabelPage';
import ShipmentsPage from './app/pages/shipments/ShipmentsPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import BatchesPage from './app/pages/BatchesPage';
import AuctionLabelGeneratorPage from './app/pages/AuctionLabelGeneratorPage';
import ProductionPalletsLabelPage from './app/pages/ProductionPalletsLabelPage';
import SignIn from './app/components/user/SignIn';

import useSignInHook from './redux/hooks/useSignInHook';
import Refresh from './app/components/Refresh';

function App() {
  const { user, signIn, signOut } = useSignInHook();

  return (
    <div className="h-screen base-card">
      <div className="App">
        {user == null ? (
          <SignIn signIn={signIn} />
        ) : (
          <BrowserRouter>
            <Header signOut={signOut} />
            <Refresh />
            <div className="flex flex-1 h-full p-5 pt-0 dark:bg-gray-800">
              <Routes>
                <Route path="/shipments" element={<ShipmentsPage />} />

                {/* /mf routes will not check database */}
                <Route path="/fnsku-product-label/mf" element={<MfFnSkuLabelPage />} />

                <Route path="/fnsku-product-label" element={<FnSkuLabelPage />}>
                  <Route path=":batchidOrSku" />
                  <Route path=":batchidOrSku/:qtyToPrint" />
                  <Route path=":batchidOrSku/:expirationDate/:qtyToPrint" />
                </Route>

                <Route path="/batchid/:batchid" element={<ProductLabelPage />} />
                <Route path="/label-generator" element={<ProductLabelPage />} />
                <Route path="/" element={<ProductLabelPage />} />

                {/* Outbound pallet label */}
                <Route path="/outbound-pallets-generator" element={<OutboundPalletsLabelPage />} />
                <Route path="/outboundpalletlabels" element={<OutboundPalletsLabelPage />}>
                  <Route path=":shipmentName" />
                </Route>

                {/* Pro pallet label */}
                <Route path="/pro-pallets-generator" element={<PalletProCarrierLabelPage />} />
                <Route path="/propalletlabels" element={<PalletProCarrierLabelPage />}>
                  <Route path=":shipmentName" />
                </Route>

                {/* Proudction pallet label */}
                <Route
                  path="/production-pallets-generator"
                  element={<ProductionPalletsLabelPage />}
                />
                <Route path="/productionpalletlabels" element={<ProductionPalletsLabelPage />}>
                  <Route path=":shipmentName" />
                </Route>

                <Route path="/products" element={<ProductsPage />} />

                <Route path="/batches" element={<BatchesPage />} />

                <Route path="/liquidation" element={<AuctionLabelGeneratorPage />} />

                <Route path="/productionhold/batchid/:batchid" element={<ProductionHoldPage />} />
                <Route path="/productionhold" element={<ProductionHoldPage />} />

                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
            <ToastContainer hideProgressBar={true} autoClose={3000} />
          </BrowserRouter>
        )}
      </div>
    </div>
  );
}

export default App;
