import { useSelector } from "react-redux";
import { doRefreshAll } from "../../../redux/slices/thunks/refresh-helper";
import { useAppDispatch } from "../../hooks";
import { RootState } from "../../store";

function ManualRefreshButton() {
  const dispatch = useAppDispatch();
  const isLoading = useSelector((x: RootState) => x.data.isLoading);

  return (
    <button
      onClick={() => dispatch(doRefreshAll())}
      disabled={isLoading}
      type="button"
      className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
    >
      <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 1v5h-5M2 19v-5h5m10-4a8 8 0 0 1-14.947 3.97M1 10a8 8 0 0 1 14.947-3.97"
        />
      </svg>
    </button>
  );
}

export default ManualRefreshButton;
