import { toast } from "react-toastify";

import PDFDialog from "../components/modals/PDFDialog";
import QtyPrintComponent from "../components/form/QtyPrintComponent";
import usePdfDialogHook from "../../redux/hooks/usePdfDialogHook";
import Card from "../components/cards/Card";
import PrintCardsContainer from "../components/cards/PrintCardsContainer";
import PageHeader from "../components/page/PageHeader";

function AuctionLabelGeneratorPage() {
  const { canGenerate, isPDFDialogOpen, setIsPDFDialogOpen, pdfBase64, print } =
    usePdfDialogHook();

  const reprint = async (apiUrl: string) => {
    await print({
      apiUrl,
      data: {
        reprint: true,
        preview: false,
      },
    });
  };

  const printLabel = async (printQty: number, apiUrl: string) => {
    if (printQty) {
      await print({
        apiUrl,
        data: {
          printQty,
          preview: false,
        },
      });
    } else toast.warn("Missing inputs");
  };

  return (
    <div className="flex flex-col w-full">
      <PageHeader headingText="Auction Label Generator" />
      <>
        <Card headerText="Liquidation Box Labels" className="mb-5">
          <QtyPrintComponent
            apiUrl="liquidation-box-label"
            canGenerate={canGenerate}
            showQtyButtons={false}
            generateButtonText="Generate Liquidation Box Label"
            printFn={printLabel}
            reprintFn={reprint}
          />
        </Card>

        <QtyPrintComponent
          apiUrl="liquidation-lot-code-label"
          canGenerate={canGenerate}
          showQtyButtons={true}
          generateButtonText="Generate Liquidation Lot Codes"
          printFn={printLabel}
          reprintFn={reprint}
          twoColumns={true}
          leftCardHeaderText="Liquidation Lot Codes"
        />
        <PDFDialog
          isOpen={isPDFDialogOpen}
          setIsOpen={setIsPDFDialogOpen}
          pdf={pdfBase64}
        />
      </>
    </div>
  );
}

export default AuctionLabelGeneratorPage;
