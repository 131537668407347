import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { Batch, Product, Shipment } from "../../../app/data";
import {
  getShipments,
  getGoogleSheetShipments,
  upsertShipments,
  updateBatches,
  getGoogleSheetBatches,
  getBatches,
  getProducts,
  saveShipment,
  saveItem,
  getGoogleSheetProducts,
  upsertProducts,
} from "../../api/app-api";
import {
  setBatchAndProducts,
  setBatchAndProductsCanPullGoogleSheet,
  setBatchAndProductsInfo,
  setBatchAndProductsLastUpdatedTime,
  setIsInitialLoad,
  setIsLoading,
  setProducts,
  setProductsCanPullGoogleSheet,
  setProductsInfo,
  setProductsLastUpdatedTime,
  setShipments,
  setShipmentsCanPullGoogleSheet,
  setShipmentsInfo,
  setShipmentsLastUpdatedTime,
} from "../dataSlice";
import { tryGetInt, tryGetString } from "../../../app/helpers/value-helper";
import { areBatchesEqual } from "../../../app/helpers/batch-comparer";
import { defaultFrom } from "../../../app/options";
import { areProductsEqual } from "../../../app/helpers/item-comparer";
import { RootState } from "../../../app/store";

/* Refresh batches (shipments from this sheety api don't have pro number and pallet count) */

// 2023-09-29
// It is normal that it refreshes randomly twice or three times in a row.
// The expiration date is a lookup against another table so when the page is loaded/refreshed or when there is achange in the underlying table it updates.

// 2023-10-09
// Products are coming from a different sheet.
export const refreshBatchesAndProducts = createAsyncThunk("data/refreshBatchesAndProducts", async (_, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;
  dispatch(setIsLoading(true));
  dispatch(setBatchAndProductsCanPullGoogleSheet(false));
  dispatch(setBatchAndProductsInfo("Pulling data from sheety api..."));

  // Pull data from db & googleBatches
  let [batches, shipments, products, googleBatches] = await Promise.all([getBatches(), getShipments(), getProducts(), getGoogleSheetBatches()]);

  if (batches && products && shipments) {
    try {
      const pendingCreateBatch: Batch[] = [];
      const pendingUpdateBatches: Batch[] = [];
      const pendingRemoveBatches: Batch[] = [];

      // If a shipment / product got created / updated, we don't want to do it again.
      // Sometimes a shipment / product can be linked to different new batches.
      const savedShipments = [];

      if (googleBatches) {
        for (const batchRow of googleBatches.batchLabelInformation) {
          // 1st empty row is the end of the sheet
          if (batchRow.batchItemId.toString().trim() === "") {
            break;
          }

          // This could do better
          let batch: Batch = {
            batch_id: batchRow.batchItemId.toString().trim(),
            assigned_pl: batchRow.assignedPl.toString().trim(),
            shipment_id: batchRow.shipmentId.toString().trim(),
            shipment_destination_fc: batchRow.destinationFc.toString().trim(),
            shipment_fc_address: batchRow.fcAddress.toString().trim(),
            shipment_fc_address_basic: batchRow.fcAddress.toString().trim(),
            shipment_name: batchRow.shipmentName.toString().trim(),

            product_id: batchRow.sku.toString().trim(),
            product_sku: batchRow.sku.toString().trim(),
            product_name: batchRow.productName.toString().trim(),
            product_image: batchRow.image.toString().trim(),
            product_fnsku: batchRow.fnsku.toString().trim(),
            product_asin: batchRow.asin.toString().trim(),
            expiration_date: batchRow.expirationDate.toString().trim(),
            production_quantity: tryGetInt(batchRow.productionQuantity),
            quantity_per_box: tryGetInt(batchRow.quantityPerBox),
            identical_boxes: tryGetInt(batchRow.identicalBoxes),
            print_fnsku_label: batchRow.printFnskuOnLabel === "TRUE" ? 1 : 0,
          };

          // Fix the shipment address
          batch.shipment_fc_address = `FBA SJK Enterprises LLC\n${batch.shipment_destination_fc}\n${batchRow.fcAddress
            .toString()
            .trim()}\nUnited States`;

          // Shipment or product for this batch
          if (batch.shipment_id.trim() !== "") {
            const foundShipment = shipments?.find((x) => x.shipment_id === batch.shipment_id);
            // If its shipment data changes or new shipment
            const shouldShipmentUpdate =
              (!foundShipment ||
                !(
                  foundShipment.shipment_name === batch.shipment_name &&
                  foundShipment.packing_list_number === batch.assigned_pl &&
                  foundShipment.destination_address === batch.shipment_fc_address &&
                  foundShipment.destination_address_basic === batch.shipment_fc_address_basic &&
                  foundShipment.destination_fc === batch.shipment_destination_fc
                )) &&
              savedShipments.find((x) => x === batch.shipment_id) == null;

            if (shouldShipmentUpdate) {
              console.log(`Save shipment: ${batch.shipment_id}`);
              await saveShipment({
                shipment_id: batch.shipment_id,
                packing_list_number: batch.assigned_pl,
                shipment_name: batch.shipment_name,
                amazon_reference_id: "",
                destination_fc: batch.shipment_destination_fc,
                from_address: defaultFrom,
                destination_address: batch.shipment_fc_address,
                destination_address_basic: batch.shipment_fc_address_basic,
                created_date: moment().format("L"),
                is_active: 1,
              });
              savedShipments.push(batch.shipment_id);
            }
          }

          // Don't upsert products here, do it in the refreshProducts

          // New or update
          const foundBatchFromDb = batches.find((x: Batch) => x.batch_id.trim().toLocaleLowerCase() === batch.batch_id.trim().toLocaleLowerCase());
          if (!foundBatchFromDb) pendingCreateBatch.push(batch);
          else {
            // Now we compare if we need to update it or not
            if (areBatchesEqual(foundBatchFromDb, batch) === false) {
              console.log(`Update batch: ${batch.batch_id}`);
              pendingUpdateBatches.push(batch);
            } else {
              console.log("This batch is in sync. Skipped.");
            }
          }
        }

        if (pendingCreateBatch.length > 0 || pendingUpdateBatches.length > 0 || pendingRemoveBatches.length > 0) {
          dispatch(
            setBatchAndProductsInfo(
              `Found ${pendingCreateBatch.length} new batches | ${pendingUpdateBatches.length} updated batches | ${pendingRemoveBatches.length} deteled batches. Updating database...`
            )
          );
          const d = await updateBatches({
            newBatches: pendingCreateBatch,
            updatedBatches: pendingUpdateBatches,
            removedBatches: pendingRemoveBatches,
          });

          if (d.success) {
            dispatch(setBatchAndProductsInfo("Updated database"));

            // Done updating or whatever, refetch and return
            console.log("Done refreshBatchesAndProducts. Refetch batches & products from db...");
            [batches, shipments, products] = await Promise.all([getBatches(), getShipments(), getProducts()]);
          } else {
            toast.warn("Failed to update database. Try again later");
          }
        } else {
          // Nothing needs to be updated
          dispatch(setBatchAndProductsInfo("Database is synced with Google sheet."));
        }

        const n = (moment() as any).tz("America/Chicago");
        dispatch(setBatchAndProductsLastUpdatedTime(n.format("LTS")));
      }
    } catch (err) {
      console.log(err);
    }
  }
  dispatch(setBatchAndProductsCanPullGoogleSheet(true));
  dispatch(setIsLoading(false));
  dispatch(
    setBatchAndProducts({
      batches,
      shipments,
      products,
    })
  );

  return "";
});

/* Refresh shipments will only do shipments but it pulls the pro number and pallet count too. *This is a different google sheet */

export const refreshShipments = createAsyncThunk("data/refreshShipments", async (_, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;
  dispatch(setIsLoading(true));
  dispatch(setShipmentsCanPullGoogleSheet(false));
  dispatch(setShipmentsInfo("Pulling data from sheety api..."));

  // Get shipments from db
  // Get shipments from Google sheet
  const [dbShipments, googleShipments] = await Promise.all([getShipments(), getGoogleSheetShipments()]);

  let result: Shipment[] = dbShipments;
  const newShipments: any[] = [];
  const updatedShipments: any[] = [];

  if (googleShipments) {
    for (const row of googleShipments.outboundShipments) {
      if (row.shipmentId?.trim() === "") continue;

      const inactiveStatuses = ["In Transit", "Checked In", "Complete"];

      // Parse data
      let shipment: Shipment = {
        shipment_id: row.shipmentId.toString().trim(),
        packing_list_number: String(row.packingList).trim(),
        shipment_name: `PackingList_${String(row.packingList).trim()}`,

        from_address: "SJK ENTERPRISES LLC\n9925 N ALPINE RD\nMACHESNEY PARK, IL 61115\nUnited States",
        destination_fc: row.destinationFc ?? "".toString().trim(),
        destination_address: `FBA SJK Enterprises LLC\n${row.destinationFc ?? "".toString().trim()}\n${
          row.fcAddress ?? "".toString().trim().toString().trim()
        }\nUnited States`,
        destination_address_basic: row.fcAddress ?? "".toString().trim(),
        amazon_reference_id: row["amazonReferenceId/po"]?.toString()?.trim(),
        status: row.status.trim(), ///////////////////////////////// also why is this null in the db?

        is_active: inactiveStatuses.find((x) => x === row.status.trim()) == null ? 1 : 0,

        pro_number: (row.proNumber ?? "").toString().trim(),
        pallet_count: (row.palletCount ?? "").toString().trim(),
      };

      // New or update
      const foundShipmentFromDb = dbShipments.find(
        (x: any) => x.shipment_id.trim().toLocaleLowerCase() === shipment.shipment_id.trim().toLocaleLowerCase()
      );
      if (foundShipmentFromDb == null) newShipments.push(shipment);
      else if (foundShipmentFromDb) {
        // Now we compare if we need to update it or not
        if (
          !(
            foundShipmentFromDb.packing_list_number === shipment.packing_list_number &&
            foundShipmentFromDb.shipment_name?.trim() === shipment.shipment_name?.trim() &&
            foundShipmentFromDb.amazon_reference_id?.trim() === shipment.amazon_reference_id?.trim() &&
            foundShipmentFromDb.destination_fc?.trim() === shipment.destination_fc?.trim() &&
            foundShipmentFromDb.destination_address?.trim() === shipment.destination_address?.trim() &&
            foundShipmentFromDb.destination_address_basic === shipment.destination_address_basic &&
            foundShipmentFromDb.from_address?.trim() === shipment.from_address?.trim() &&
            foundShipmentFromDb.is_active === shipment.is_active &&
            foundShipmentFromDb.status?.trim() === shipment.status?.trim() &&
            foundShipmentFromDb.pro_number === shipment.pro_number &&
            foundShipmentFromDb.pallet_count === shipment.pallet_count
          )
        ) {
          console.log(`Update shipment: ${shipment.shipment_id}`);
          updatedShipments.push(shipment);
        }
      }
    }

    if (newShipments.length > 0 || updatedShipments.length > 0) {
      dispatch(setShipmentsInfo(`Found ${newShipments.length} new shipments | ${updatedShipments.length} updated shipments. Updating database...`));

      const d = await upsertShipments({
        newShipments,
        updatedShipments,
      });
      if (d.success) {
        dispatch(setShipmentsInfo("Updated database"));

        // Done updating or whatever, refetch and return
        console.log("Done updateShipments. Refetch shipments from db...");
        result = await getShipments();
      } else {
        toast.warn("Failed to update database. Try again later");
      }
    } else {
      // Nothing needs to be updated
      dispatch(setShipmentsInfo("Database is synced with Google sheet."));
    }

    const n = (moment() as any).tz("America/Chicago");
    dispatch(setShipmentsLastUpdatedTime(n.format("LTS")));
  }
  dispatch(setShipmentsCanPullGoogleSheet(true));
  dispatch(setIsLoading(false));
  dispatch(setShipments(result));
});

export const refreshProducts = createAsyncThunk("data/refreshProducts", async (_, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;
  dispatch(setIsLoading(true));
  dispatch(setProductsCanPullGoogleSheet(false));
  dispatch(setProductsInfo("Pulling data from sheety api..."));

  const [dbProducts, googleProducts] = await Promise.all([getProducts(), getGoogleSheetProducts()]);
  let result: Product[] = dbProducts;
  const newProducts: Product[] = [];
  const updatedProducts: Product[] = [];

  if (googleProducts) {
    for (const row of googleProducts.itemInformation) {
      if (row.merchantSku?.toString().trim() === "") continue;

      try {
        // Parse data
        let product: Product = {
          sku: tryGetString(row.merchantSku),
          name: tryGetString(row.productName),
          fn_sku: tryGetString(row.fnsku),
          asin: tryGetString(row.asin),
          image: tryGetString(row.image),
        };

        // New or update
        const foundFromDB = dbProducts.find((x: Product) => x.sku.trim().toLocaleLowerCase() === product.sku?.trim().toLocaleLowerCase());
        if (foundFromDB == null) newProducts.push({ ...product, product_id: product.sku, condition: "NEW ITEM" });
        else if (foundFromDB) {
          // Now we compare if we need to update it or not
          if (areProductsEqual(foundFromDB, product) === false) {
            console.log(`Update item: ${product.sku}`);
            updatedProducts.push(product);
          } else {
            console.log("This item is in sync. Skipped.");
          }
        }
      } catch (err) {
        debugger;
      }
    }

    if (newProducts.length > 0 || updatedProducts.length > 0) {
      dispatch(setShipmentsInfo(`Found ${newProducts.length} new products | ${updatedProducts.length} updated products. Updating database...`));
      const d = await upsertProducts({ newProducts, updatedProducts });
      if (d.success) {
        dispatch(setShipmentsInfo("Updated database"));
        // Done updating or whatever, refetch and return
        console.log("Done refreshProducts. Refetch products from db...");
        result = await getProducts();
      } else {
        toast.warn("Failed to update database. Try again later");
      }
    } else {
      // Nothing needs to be updated
      dispatch(setProductsInfo("Database is synced with Google sheet."));
    }
    const n = (moment() as any).tz("America/Chicago");
    dispatch(setProductsLastUpdatedTime(n.format("LTS")));
  }
  dispatch(setProductsCanPullGoogleSheet(true));
  dispatch(setIsLoading(false));
  dispatch(setProducts(result));
});

export const doRefreshAll = createAsyncThunk("data/doRefreshAll", async (_, thunkAPI) => {
  const { getState, dispatch } = thunkAPI;
  await dispatch(refreshProducts()); // <-- Pull products
  await dispatch(refreshBatchesAndProducts()); // <-- Pull batches
  await dispatch(refreshShipments()); // <-- Pull shipments, this one has pro number and pallet count

  // No longer init load
  const state: RootState = getState() as RootState;
  if (state.data.isInitialLoad) {
    await dispatch(setIsInitialLoad(false));
  }
});
