import { Auth, Hub } from "aws-amplify";
import { useEffect } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setUser } from "../slices/userSlice";

export default function useSignInHook() {
  const user = useAppSelector(state => state.user.loggedInUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          if (data.attributes)
            dispatch(
              setUser({
                name: data.attributes.name,
                picture: data.attributes.picture,
              })
            );
          break;
        case "signOut":
          dispatch(setUser(null));
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((data) => {
        dispatch(
          setUser({
            name: data.attributes.name,
            picture: data.attributes.picture,
          })
        );
      })
      .catch(() => console.log("Not signed in"));

    return unsubscribe;
  }, []);

  const signIn = () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  const signOut = () => {
    Auth.signOut();
  };

  return {
    user,
    signIn,
    signOut,
  };
}
