import React from "react";
import OutboundAndPalletProPage from "./common/outbound-and-pallet-pro/OutboundAndPalletProPage";

function OutboundPalletsLabelPage() {
  return (
    <OutboundAndPalletProPage apiUrl="pallet-label" headingText="Outbound Pallet Label Generator" allowToAddPallet />
  );
}

export default OutboundPalletsLabelPage;
