import TablePageButtons from "./TablePageButtons";
import { flexRender } from '@tanstack/react-table'

const Dtable = ({ table, columns }: { table: any, columns: any }) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-blue-100 dark:bg-gray-700 dark:text-gray-400">
          {table.getHeaderGroups().map((headerGroup: any) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => (
                <th className="px-6 py-3" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: any, i: number) => (
            <tr key={row.id} className={`${i % 2 === 0 ? '' : 'bg-blue-50  dark:bg-gray-900'} hover:bg-gray-200 dark:hover:bg-gray-600`}>
              {row.getVisibleCells().map((cell: any) => {
                return (
                  <td key={cell.id} >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <TablePageButtons table={table} />
    </div>
  )
}

export default Dtable