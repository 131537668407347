import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';

import Card from "../components/cards/Card";
import SInput from "../components/form/SInput";
import Loading from "../components/Loading";
import { Shipment } from "../data";
import PDFDialog from "../components/modals/PDFDialog";
import { defaultFrom } from "../options";
import ShipmentDropdown from "../components/form/ShipmentDropdown";
import usePdfDialogHook from "../../redux/hooks/usePdfDialogHook";
import QtyPrintComponent from "../components/form/QtyPrintComponent";
import PrintCardsContainer from "../components/cards/PrintCardsContainer";
import PageHeader from "../components/page/PageHeader";
import { useSelector } from "react-redux";
import { RootState } from "../store";

function ProductionPalletsLabelPage() {
  const { canGenerate, isPDFDialogOpen, setIsPDFDialogOpen, pdfBase64, print } = usePdfDialogHook();

  const { shipmentName: urlShipmentName } = useParams();

  const isShipmentsLoading = useSelector((x: RootState) => x.data.isLoading);
  const shipments = useSelector((x: RootState) => x.data.shipments);

  const [selectedShipment, setselectedShipment] = useState<Shipment | undefined | null>(null);
  const [shipmentID, setShipmentID] = useState<string>("");

  useEffect(() => {
    if (shipments.length > 0 && urlShipmentName) {
      const found = shipments.find((x) => x.shipment_name === urlShipmentName);
      if (found) {
        doShipmentChange(found.shipment_id);
      }
    }
  }, [shipments]);

  const doShipmentChange = (value: any) => {
    setShipmentID(value);
    if (value && value.trim() !== "") {
      // Get shipment
      const foundShipment = shipments?.find((x) => x.shipment_id.trim().toLocaleLowerCase() === value.trim().toLowerCase());
      setselectedShipment(foundShipment);
    }
  };
  const print4x6 = async (printQty: number, apiUrl: string) => {
    if (selectedShipment) {
      await print({
        apiUrl,
        data: {
          shipmentID: selectedShipment?.shipment_id,
          shipmentName: selectedShipment?.shipment_name,
          from: defaultFrom,
          to: selectedShipment?.destination_address,
          shipmentCode: selectedShipment?.shipment_id,
          amazonRefID: selectedShipment?.amazon_reference_id,
        },
      });
    } else toast.warn("Missing inputs");
  };
  const print3x2 = async (printQty: number, apiUrl: string) => {
    // The backend will generate the pallet ids
    const pallets = [];
    for (let index = 0; index < printQty; index++) {
      pallets.push({});
    }

    await print({
      apiUrl,
      data: { pallets },
    });
  };
  const reprint = async (apiUrl: string) => {
    await print({
      apiUrl,
      data: { reprint: true },
    });
  };

  return (
    <div className="flex flex-col w-full">
      <PageHeader headingText="Production Pallet Label Generator" />
      {isShipmentsLoading && <Loading />}
      {shipments && shipments.length > 0 && (
        <>
          <PrintCardsContainer
            leftCard={
              <Card headerText="4x6 Labels (For Ship Alone Pallets)">
                <div className="flex flex-wrap">
                  <div className="flex flex-col w-3/5 gap-4 mt-3">
                    <ShipmentDropdown
                      name="Shipment"
                      value={shipmentID}
                      onChange={doShipmentChange}
                      shipments={shipments.filter((x) => x.is_active)}
                    ></ShipmentDropdown>
                    <SInput name="Shipment Name" value={selectedShipment?.shipment_name} disabled={true} />
                    <SInput name="Amazon Reference ID" value={selectedShipment?.amazon_reference_id} disabled={true} />
                  </div>
                  <div className="flex flex-wrap w-2/5">
                    <div className="flex flex-col w-full px-5 mt-3">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Destination Address</label>
                      <textarea
                        rows={7}
                        value={selectedShipment?.destination_address ?? ""}
                        disabled={true}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      />
                    </div>
                  </div>
                </div>
              </Card>
            }
            rightCard={
              <Card headerText="Print">
                <div className="flex flex-col gap-5">
                  <QtyPrintComponent
                    apiUrl="production-pallet-label4x6"
                    canGenerate={canGenerate}
                    showQtyButtons={false}
                    generateButtonText="Generate 4x6 Label"
                    printFn={print4x6}
                    reprintFn={reprint}
                    buttonsColDir={true}
                  />
                </div>
              </Card>
            }
          />

          <QtyPrintComponent
            apiUrl="production-pallet-label3x2"
            canGenerate={canGenerate}
            showQtyButtons={true}
            Qtys={[1, 5, 25]}
            generateButtonText="Generate 3x2 Label"
            printFn={print3x2}
            reprintFn={reprint}
            twoColumns={true}
            leftCardHeaderText="3x2 Labels (For All Pallets)"
          />

          <PDFDialog isOpen={isPDFDialogOpen} setIsOpen={setIsPDFDialogOpen} pdf={pdfBase64} />
        </>
      )}
    </div>
  );
}

export default ProductionPalletsLabelPage;
