import useGeneratorForm from "../components/form/box-label-and-production-hold/useGeneratorForm";

function ProductLabelPage() {
  const { form } = useGeneratorForm({
    title: "Box Label Generator",
    apiUrl: "barcode-label",
    printText: "Close Box and Print",
    reprintText: "Reprint Last Box",
  });

  return <>{form}</>;
}

export default ProductLabelPage;
