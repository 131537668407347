import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Card from '../../../components/cards/Card';
import SInput from '../../../components/form/SInput';
import Loading from '../../../components/Loading';
import { Shipment, Pallet } from '../../../data';
import { toast } from 'react-toastify';
import PDFDialog from '../../../components/modals/PDFDialog';
import { defaultFrom } from '../../../options';
import ShipmentDropdown from '../../../components/form/ShipmentDropdown';
import usePdfDialogHook from '../../../../redux/hooks/usePdfDialogHook';
import PrintCardsContainer from '../../../components/cards/PrintCardsContainer';
import PageHeader from '../../../components/page/PageHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

type OutboundAndPalletProPageProps = {
  apiUrl: string;
  headingText: string;
  allowToAddPallet: boolean;
};

function OutboundAndPalletProPage({
  apiUrl,
  headingText,
  allowToAddPallet
}: OutboundAndPalletProPageProps) {
  const { canGenerate, isPDFDialogOpen, setIsPDFDialogOpen, pdfBase64, print } = usePdfDialogHook();

  const { shipmentName: urlShipmentName } = useParams();

  const isShipmentsLoading = useSelector((x: RootState) => x.data.isLoading);
  const shipments = useSelector((x: RootState) => x.data.shipments);

  const [selectedShipment, setselectedShipment] = useState<Shipment | undefined | null>(null);
  const [shipmentID, setShipmentID] = useState<string>('');

  const [pallets, setPallets] = useState<Pallet[]>([]);

  useEffect(() => {
    addPallet();
  }, []);

  useEffect(() => {
    if (shipments.length > 0 && urlShipmentName) {
      const found = shipments.find((x) => x.shipment_name === urlShipmentName);
      if (found) {
        doShipmentChange(found.shipment_id);
      }
    }
  }, [shipments]);

  const addPallet = () => {
    setPallets([...pallets, { weight: '', height: '', numberOfBoxes: '' }]);
  };

  const doShipmentChange = (value: any) => {
    setShipmentID(value);
    if (value && value.trim() !== '') {
      // Get shipment
      const foundShipment = shipments?.find(
        (x) => x.shipment_id.trim().toLocaleLowerCase() === value.trim().toLowerCase()
      );
      setselectedShipment(foundShipment);
    }
  };
  const printPalletLabel = async () => {
    if (selectedShipment) {
      await print({
        apiUrl,
        data: {
          shipmentID: selectedShipment?.shipment_id,
          shipmentName: selectedShipment?.shipment_name,
          from: defaultFrom,
          to: selectedShipment?.destination_address,
          destinationFc: selectedShipment?.destination_fc,
          destinationFcBasic: selectedShipment?.destination_address_basic,
          shipmentCode: selectedShipment?.shipment_id,
          packingListNumber: selectedShipment?.packing_list_number,
          amazonRefID: selectedShipment?.amazon_reference_id,
          pallets,
          proNumber: selectedShipment?.pro_number,
          palletCount: selectedShipment?.pallet_count
        }
      });
    } else toast.warn('Missing inputs');
  };
  const reprintPalletLabel = async () => {
    await print({
      apiUrl,
      data: { reprint: true }
    });
  };
  const clearAll = () => {
    setselectedShipment(null);
    setPallets([{ weight: '', height: '', numberOfBoxes: '' }]);
  };

  return (
    <div className="flex flex-col w-full">
      <PageHeader headingText={headingText} />
      {isShipmentsLoading && <Loading />}
      {shipments && shipments.length > 0 && (
        <>
          <PrintCardsContainer
            leftCard={
              <Card headerText="1. Shipment ID & Destination Info">
                <div className="flex flex-wrap">
                  <div className="flex flex-col w-3/5 gap-4 mt-3">
                    {shipments && (
                      <ShipmentDropdown
                        name="Shipment"
                        value={shipmentID}
                        onChange={doShipmentChange}
                        shipments={shipments.filter((x) => x.is_active)}
                      ></ShipmentDropdown>
                    )}
                    <SInput
                      name="Shipment Name"
                      value={selectedShipment?.shipment_name}
                      disabled={true}
                    />
                    <SInput
                      name="Amazon Reference ID"
                      value={selectedShipment?.amazon_reference_id}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-wrap w-2/5">
                    <div className="flex flex-col w-full px-5 mt-3">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Destination Address
                      </label>
                      <textarea
                        rows={7}
                        value={selectedShipment?.destination_address ?? ''}
                        disabled={true}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      />
                    </div>
                  </div>
                </div>
              </Card>
            }
            rightCard={
              <Card headerText="Print">
                <div className="flex flex-wrap gap-5">
                  <button
                    className="w-full base-gray-button"
                    hidden={!allowToAddPallet}
                    disabled={!canGenerate}
                    onClick={addPallet}
                  >
                    Add Pallet
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={() => {
                      printPalletLabel();
                    }}
                    className="w-full base-green-button"
                  >
                    Close Box and Print
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={() => {
                      reprintPalletLabel();
                    }}
                    className="w-full base-blue-button"
                  >
                    Reprint Last Pallet
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={clearAll}
                    className="w-full base-red-button"
                  >
                    Clear All
                  </button>
                </div>
              </Card>
            }
          />

          <PDFDialog isOpen={isPDFDialogOpen} setIsOpen={setIsPDFDialogOpen} pdf={pdfBase64} />

          <div hidden={!allowToAddPallet}>
            <Card headerText="2. Pallets">
              {pallets.map((x, i) => (
                <div key={`pallet_${i}`} className="grid grid-cols-3 gap-5 mt-5">
                  <SInput
                    name="Height"
                    value={x.height}
                    onChange={(val: any) => {
                      pallets[i].height = val;
                      setPallets([...pallets]);
                    }}
                  />
                  <SInput
                    name="Weight"
                    value={x.weight}
                    onChange={(val: any) => {
                      pallets[i].weight = val;
                      setPallets([...pallets]);
                    }}
                  />
                  <SInput
                    name="Number of Boxes"
                    value={x.numberOfBoxes}
                    onChange={(val: any) => {
                      pallets[i].numberOfBoxes = val;
                      setPallets([...pallets]);
                    }}
                  />
                </div>
              ))}
            </Card>
          </div>
        </>
      )}
    </div>
  );
}

export default OutboundAndPalletProPage;
