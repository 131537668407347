import React, { useState } from "react";
import Card from "../cards/Card";
import PrintCardsContainer from "../cards/PrintCardsContainer";

import SInput from "./SInput";

type QtyPrintComponentType = {
  apiUrl: string;
  canGenerate: boolean;
  generateButtonText: string;
  printFn: (printQty: number, apiUrl: string) => void;
  reprintFn: (apiUrl: string) => void;
  showQtyButtons: boolean;
  Qtys?: number[];
  twoColumns?: boolean;
  leftCardHeaderText?: string;
  buttonsColDir?: boolean;
};

const QTYButtons = ({
  Qtys,
  setPrintQty,
  printQty,
}: {
  Qtys?: number[];
  setPrintQty: any;
  printQty: string;
}) => {
  return (
    <>
      <p className="mt-5 base-p">Select QTY to print</p>
      <div className="flex flex-col flex-wrap gap-2 mt-5">
        <div className="flex flex-wrap gap-1">
          {(Qtys
            ? Qtys
            : [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 16, 18, 20, 24, 36, 48, 72,
                96,
              ]
          ).map((x) => (
            <button
              className="base-gray-button"
              key={`printQty_` + x}
              onClick={() => {
                setPrintQty(x.toString());
              }}
            >
              {x}
            </button>
          ))}
        </div>
        <SInput
          type="number"
          name=""
          value={printQty}
          onChange={setPrintQty}
        ></SInput>
      </div>
    </>
  );
};

const PrintButtons = ({
  canGenerate,
  showQtyButtons,
  printQty,
  apiUrl,
  printFn,
  reprintFn,
  generateButtonText,
  twoColumns,
  buttonsColDir,
}: {
  canGenerate: boolean;
  showQtyButtons: boolean;
  printQty: string;
  apiUrl: string;
  printFn: any;
  reprintFn: any;
  generateButtonText: string;
  twoColumns?: boolean;
  buttonsColDir?: boolean;
}) => (
  <div
    className={`flex  ${
      twoColumns || buttonsColDir ? "flex-col" : ""
    }  w-full gap-4`}
  >
    <button
      disabled={!canGenerate}
      onClick={() => {
        const qty = showQtyButtons ? printQty : "1";
        if (parseInt(qty) > 0) printFn(parseInt(qty), apiUrl);
      }}
      className="base-green-button"
    >
      {generateButtonText}
    </button>
    <button
      disabled={!canGenerate}
      onClick={() => {
        reprintFn(apiUrl);
      }}
      className="base-blue-button"
    >
      Reprint
    </button>
  </div>
);

function QtyPrintComponent(props: QtyPrintComponentType) {
  const [printQty, setPrintQty] = useState<string>("");

  return (
    <>
      {props.twoColumns ? (
        <PrintCardsContainer
          leftCard={
            <Card headerText={props.leftCardHeaderText}>
              <div className="flex flex-col w-full">
                {props.showQtyButtons && (
                  <QTYButtons
                    Qtys={props.Qtys}
                    printQty={printQty}
                    setPrintQty={setPrintQty}
                  />
                )}
              </div>
            </Card>
          }
          rightCard={
            <Card headerText="Print">
              <div className="flex flex-wrap gap-5">
                <PrintButtons
                  apiUrl={props.apiUrl}
                  canGenerate={props.canGenerate}
                  generateButtonText={props.generateButtonText}
                  printFn={props.printFn}
                  reprintFn={props.reprintFn}
                  printQty={printQty}
                  showQtyButtons={props.showQtyButtons}
                  twoColumns={props.twoColumns}
                  buttonsColDir={props.buttonsColDir}
                />
              </div>
            </Card>
          }
        />
      ) : (
        <>
          {props.showQtyButtons && (
            <QTYButtons
              Qtys={props.Qtys}
              printQty={printQty}
              setPrintQty={setPrintQty}
            />
          )}

          <PrintButtons
            apiUrl={props.apiUrl}
            canGenerate={props.canGenerate}
            generateButtonText={props.generateButtonText}
            printFn={props.printFn}
            reprintFn={props.reprintFn}
            printQty={printQty}
            showQtyButtons={props.showQtyButtons}
            twoColumns={props.twoColumns}
            buttonsColDir={props.buttonsColDir}
          />
        </>
      )}
    </>
  );
}

export default QtyPrintComponent;
