import moment from "moment";
import DatePicker from "react-datepicker";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import usePdfDialogHook from "../../../../redux/hooks/usePdfDialogHook";
import { Product, Shipment, Batch } from "../../../data";
import { defaultFrom } from "../../../options";
import Card from "../../cards/Card";
import PrintCardsContainer from "../../cards/PrintCardsContainer";
import Loading from "../../Loading";
import PageHeader from "../../page/PageHeader";
import PDFDialog from "../../modals/PDFDialog";
import ShipmentDropdown from "../ShipmentDropdown";
import SInput from "../SInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

// The form is very similar in box label and production hold label
type HookType = {
  title: string;
  apiUrl: string;
  printText: string;
  reprintText: string;

  isProductionHold?: boolean;
};
const useBoxLabelAndProductionHoldForm = (input: HookType) => {
  const { canGenerate, isPDFDialogOpen, setIsPDFDialogOpen, pdfBase64, print } = usePdfDialogHook();

  const { batchid: urlBatchid } = useParams();
  const _apiUrl = input.apiUrl;

  const isInitialLoad = useSelector((x: RootState) => x.data.isInitialLoad);
  const isLoading = useSelector((x: RootState) => x.data.isLoading);
  const products = useSelector((x: RootState) => x.data.products);
  const shipments = useSelector((x: RootState) => x.data.shipments);
  const batches = useSelector((x: RootState) => x.data.batches);

  const [selectedBatch, setSelectedBatch] = useState<Batch | null | undefined>(null);
  const [selectedShipment, setSelectedShipment] = useState<Shipment | null | undefined>(null);
  const [selectedItem, setSelectedItem] = useState<Product | null | undefined>(null);

  const [batchID, setBatchID] = useState<string>("");
  const [shipmentID, setShipmentID] = useState<string>("");
  const [itemID, setItemID] = useState<string>("");
  const [itemExpiration, setitemExpiration] = useState<Date | null>(null);
  const [itemQty, setItemQty] = useState<number | null>(null);
  const [numOfBoxes, setNumOfBoxes] = useState<number | null>(null);
  const [printSKU, setPrintSKU] = useState<boolean>(false);

  useEffect(() => {
    if (batches.length > 0 && !isInitialLoad) {
      const init = () => {
        setSelectedBatch(null);
        setSelectedShipment(null);
        setSelectedItem(null);
      };

      if (!urlBatchid) init();
      // If there is a url batch id, do batchID change
      else doBatchIDChange(urlBatchid);
    }
  }, [isInitialLoad]);

  const doBatchIDChange = (value: any) => {
    setBatchID(value);
    setShipmentID("");
    setSelectedShipment(null);

    if (value && value.trim() !== "") {
      // Get batch
      const foundBatch = batches?.find((x) => x.batch_id.trim().toLowerCase() === value.trim().toLowerCase());
      const foundProduct = products.find((x) => x.sku === foundBatch?.product_sku);
      const foundShipment = shipments.find((x) => x.shipment_id === foundBatch?.shipment_id);
      if (foundBatch && foundProduct && foundShipment) {
        // If found batch, set shipmnet and item
        setSelectedBatch(foundBatch);
        setSelectedShipment(foundShipment);
        setSelectedItem(foundProduct);
        setPrintSKU(foundBatch.print_fnsku_label === 1 ? true : false);

        const eDate = moment(foundBatch.expiration_date, "L");
        if (eDate.isValid()) {
          setitemExpiration(foundBatch.expiration_date == null ? null : eDate.toDate()); // Expiration is from batch
        } else {
          setitemExpiration(null);
        }

        setItemFieldsUsingBatch(foundBatch);
      } else {
        setSelectedBatch(null);
        setSelectedShipment(null);
        setSelectedItem(null);

        clearSelectedItem();
      }
    }
  };
  const setItemFieldsUsingBatch = (batch: Batch) => {
    setItemID(batch.product_sku);
    setItemQty(batch.quantity_per_box);
    setNumOfBoxes(batch.identical_boxes);
    doItemChange(batch.product_sku);
  };
  const doShipmentChange = (value: any) => {
    setBatchID("");
    setShipmentID(value);
    setSelectedBatch(null);

    if (value && value.trim() !== "") {
      // Get shipment
      const foundShipment = shipments?.find((x) => x.shipment_id.trim().toLowerCase() === value.trim().toLowerCase());
      if (foundShipment) setSelectedShipment(foundShipment);
      else setSelectedShipment(null);
    }
  };
  const doItemChange = (value: any) => {
    setItemID(value);
    if (value && value.trim() !== "") {
      // Get item
      const foundItem = products?.find((x) => x.sku.trim().toLocaleLowerCase() === value.trim().toLowerCase());
      if (foundItem) setSelectedItem(foundItem);
      else setSelectedItem(null);
    }
  };
  const reprint = async (apiUrl: string) => {
    await print({
      apiUrl,
      data: {
        reprint: true,
        preview: false,
      },
    });
  };
  const printBoxLabel = async (apiUrl: string) => {
    // Production hold doesn't require shipment info
    if (!input.isProductionHold) {
      if (!(selectedShipment?.shipment_name && selectedShipment?.shipment_id)) {
        toast.warn("Missing shipment inputs.");
        return;
      }
    }

    if ((numOfBoxes ?? 0) > 0 && (itemQty ?? 0) > 0 && selectedItem?.name && itemQty) {
      const exp = itemExpiration == null ? "" : `,EXP:${moment(itemExpiration).format("yyyy/MM/DD")}`;

      await print({
        apiUrl,
        data: {
          numberOfBoxes: numOfBoxes,
          shipmentID: selectedShipment?.shipment_id,
          productID: selectedItem?.product_id,
          productName: selectedItem.name,
          shipmentName: `${selectedShipment?.shipment_name}`,
          shipmentCode: selectedShipment?.shipment_id,
          from: defaultFrom,
          to: selectedShipment?.destination_address,
          destination_address_basic: selectedShipment?.destination_address_basic,
          boxContentCode417: `AMZN,PO:${selectedShipment?.shipment_id},FNSKU:${selectedItem?.fn_sku},QTY:${itemQty}${exp}`,
          content: `FNSKU:${selectedItem?.fn_sku}`,
          qty: itemQty,

          product: selectedItem,
          productExpiration: itemExpiration == null ? "" : moment(itemExpiration).format("LL"),
          printfnsku: printSKU,
          batchID: batchID,
          printedTimestamp: moment().format("MMMM DD, YYYY h:mm A"),
        },
      });
    } else toast.warn("Missing inputs");
  };
  const clearSelectedItem = () => {
    setItemID("");
    setItemQty(0);
    setitemExpiration(null);
    setNumOfBoxes(0);
    setPrintSKU(false);
    setSelectedItem(null);
  };

  const form = (
    <div className="flex flex-col w-full">
      <PageHeader headingText={input.title} />
      {isLoading && <Loading />}
      {products && shipments && batches && !isInitialLoad && (
        <>
          <PrintCardsContainer
            leftCard={
              <Card headerText="1. Shipment ID & Destination Info">
                <p className="font-normal bg-red-500"></p>
                <div className="mt-5">
                  <div className="grid grid-cols-3 gap-3">
                    <div className="flex justify-center">
                      <SInput name="Batch ID" value={batchID} onChange={doBatchIDChange}></SInput>
                    </div>
                    <div className="flex items-center justify-center base-p">OR</div>
                    <div className="flex justify-center">
                      {shipments && (
                        <ShipmentDropdown
                          name="Shipment"
                          value={shipmentID}
                          onChange={doShipmentChange}
                          shipments={shipments.filter((x) => x.is_active)}
                        ></ShipmentDropdown>
                      )}
                    </div>
                  </div>
                </div>

                <div className="grid grid-flow-row gap-5 mt-5 md:grid-cols-3 sm:grid-cols-1">
                  <SInput name="Shipment ID" value={selectedShipment?.shipment_id} disabled={true} />
                  <SInput name="Shipment Name" value={selectedShipment ? `${selectedShipment.shipment_name}` : ""} disabled={true} />
                  <SInput name="Destination FC" value={selectedShipment ? `${selectedShipment.destination_fc}` : ""} disabled={true} />
                </div>
                <div className="grid grid-flow-row grid-cols-1 gap-5 mt-5">
                  <div className="">
                    <label htmlFor="shipmentAddress" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Destination Address
                    </label>
                    <textarea
                      rows={5}
                      value={selectedShipment ? selectedShipment.destination_address : ""}
                      onChange={(e) => {}}
                      id="shipmentAddress"
                      className="cursor-not-allowed shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      disabled={true}
                    />
                  </div>
                </div>
              </Card>
            }
            rightCard={
              <Card headerText="Print">
                <div className="flex flex-col gap-3">
                  <button
                    disabled={!canGenerate}
                    onClick={() => {
                      printBoxLabel(_apiUrl);
                    }}
                    className="w-full base-green-button"
                  >
                    {input.printText}
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={() => {
                      reprint(_apiUrl);
                    }}
                    className="w-full base-blue-button"
                  >
                    {input.reprintText}
                  </button>
                  <button disabled={!canGenerate} onClick={clearSelectedItem} className="w-full base-red-button">
                    Clear SKU
                  </button>

                  <div className="flex flex-col px-3">
                    <h6 className="flex font-bold text dark:text-white">{selectedItem ? selectedItem.fn_sku : ""}</h6>
                    <h6 className="flex text-sm dark:text-white">{selectedItem ? selectedItem.name : ""}</h6>
                    <div className="flex items-center justify-center w-full border rounded max-h-96 dark:bg-gray-700">
                      {selectedItem?.image ? <img alt="selected product" src={selectedItem.image} className="w-auto h-auto mt-2 max-h-28" /> : null}
                    </div>
                  </div>
                </div>
              </Card>
            }
          />

          <Card headerText="2. Items in the Box">
            <div className="flex flex-wrap">
              <div className="flex flex-col w-1/2 px-3">
                <div className="flex gap-2">
                  <div className="flex w-56">
                    <SInput name="Item ID" value={itemID} onChange={doItemChange} />
                  </div>

                  <div className="flex flex-col flex-wrap gap-3">
                    <p className="text-sm font-medium base-p">Select Number of Units in Box</p>
                    <div className="flex flex-row flex-wrap gap-2 -mt-1">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 16, 18, 20, 24, 32].map((x) => (
                        <button className="base-gray-button" key={`unit_` + x} onClick={() => setItemQty(x)}>
                          {x}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="grid grid-flow-row gap-5 mt-5 sm:grid-cols-1 md:grid-cols-3">
                  <SInput name="Quantity" value={itemQty} onChange={setItemQty} type="number" />
                  <div className="w-full">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Expiration Date</label>
                    <DatePicker
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      selected={itemExpiration}
                      onChange={(date: Date) => setitemExpiration(date)}
                    />
                  </div>
                  <div className="flex items-center">
                    <input
                      id="printsku-checkbox"
                      type="checkbox"
                      checked={printSKU}
                      onChange={(e) => setPrintSKU(e.target.checked)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    ></input>
                    <label htmlFor="printsku-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Print FNSKU on Label
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-1/2 px-3">
                <div className="flex justify-center p-2 text-white bg-black rounded-lg dark:bg-gray-900" role="alert">
                  <div className="flex items-center">
                    <div>
                      <div className="font-medium">Select Number of Identical Boxes</div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row flex-wrap gap-2 mt-5">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 16, 18, 20, 24, 36, 48, 72, 96].map((x) => (
                    <button
                      className="base-gray-button"
                      key={`identical_` + x}
                      onClick={() => {
                        setNumOfBoxes(x);
                      }}
                    >
                      {x}
                    </button>
                  ))}
                </div>

                <div className="mt-5">
                  <SInput name="Number of Identical Boxes" value={numOfBoxes} onChange={setNumOfBoxes} type="number" />
                </div>
              </div>
            </div>

            <PDFDialog isOpen={isPDFDialogOpen} setIsOpen={setIsPDFDialogOpen} pdf={pdfBase64} />
          </Card>
        </>
      )}
    </div>
  );

  return {
    form,
  };
};

export default useBoxLabelAndProductionHoldForm;
