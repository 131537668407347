import { createColumnHelper, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { Shipment } from "../../../data";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import Dtable from "../../../components/table/Dtable";

type ShipmentsTableProps = {
  setSelectedShipment: (item: any) => void;
  setIsModalOpen: (val: boolean) => void;
};

function ShipmentsTable({ setSelectedShipment, setIsModalOpen }: ShipmentsTableProps) {
  const shipments = useSelector((x: RootState) => x.data.shipments);

  const columnHelper = createColumnHelper<Shipment>();
  const columns = [
    columnHelper.accessor((row) => row.shipment_name, {
      id: "shipment_name",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>PACKING LIST NO.</span>,
    }),
    columnHelper.accessor((row) => row.shipment_id, {
      id: "id",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>SHIPMENT ID</span>,
    }),
    columnHelper.accessor((row) => row.amazon_reference_id, {
      id: "amazon_reference_id",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>AMAZON REF. ID</span>,
    }),
    columnHelper.accessor((row) => row.destination_fc, {
      id: "dest fc",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>DEST. FC</span>,
    }),
    columnHelper.accessor((row) => row.is_active, {
      id: "STATUS",
      cell: (info) => (
        <div className={`flex items-center justify-center font-bold text-white px-6 py-4 ${info.getValue() ? "bg-green-500" : "bg-red-500"} `}>
          {info.getValue() ? "Active" : "Not Active"}
        </div>
      ),
      header: () => <span>STATUS</span>,
    }),
    {
      header: "Actions",
      cell: (info: any) => (
        <div className={`flex items-center justify-center font-bold text-white px-6 py-4`}>
          <button
            className="base-green-button"
            onClick={() => {
              setSelectedShipment(info.row.original);
              setIsModalOpen(true);
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data: shipments ?? [],
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return <Dtable table={table} columns={columns} />;
}

export default ShipmentsTable;
