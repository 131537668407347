import { createColumnHelper, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { useState } from "react";

import ItemDialog from "../components/modals/ItemDialog";
import Dtable from "../components/table/Dtable";
import Loading from "../components/Loading";
import { Product } from "../data";
import Card from "../components/cards/Card";
import { useSelector } from "react-redux";
import { RootState } from "../store";

function ProductsPage() {
  const isLoading = useSelector((x: RootState) => x.data.isLoading);
  const products = useSelector((x: RootState) => x.data.products);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const columnHelper = createColumnHelper<Product>();
  const columns = [
    columnHelper.accessor((row) => row.sku, {
      id: "SKU",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>SKU</span>,
    }),
    columnHelper.accessor((row) => row.fn_sku, {
      id: "FNSKU",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>FNSKU</span>,
    }),
    columnHelper.accessor((row) => row.name, {
      id: "Item Name",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Item Name</span>,
    }),
    columnHelper.accessor((row) => row.condition, {
      id: "Condition",
      cell: (info) => <div className="basic-cell">{info.getValue()}</div>,
      header: () => <span>Condition</span>,
    }),
    {
      header: "Actions",
      cell: (info: any) => (
        <div className={`flex items-center justify-center font-bold text-white px-6 py-4`}>
          <button
            className="base-green-button"
            onClick={() => {
              setSelectedItem(info.row.original);
              setIsModalOpen(true);
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data: products ?? [],
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="flex w-full">
      <Card headerText={`Box Products`}>
        <div className="flex gap-4">
          <button
            className="p-4 mb-4 base-blue-button"
            onClick={() => {
              setSelectedItem(null);
              setIsModalOpen(true);
            }}
          >
            Add new product
          </button>
        </div>
        {isLoading && <Loading />}
        {products && <Dtable table={table} columns={columns} />}
        {isModalOpen && <ItemDialog product={selectedItem} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />}
      </Card>
    </div>
  );
}

export default ProductsPage;
