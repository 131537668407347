// TODO: turn these into variables
let apiUrl = "";
let isProd = false;
if (window.location.hostname.includes("sjkenterprisesapps") || window.location.hostname.includes("prod.")) {
  isProd = true;
}

if (isProd) {
  apiUrl = "https://5ydziz503a.execute-api.us-east-2.amazonaws.com/prod";
} else apiUrl = "https://mpfbzkpol1.execute-api.us-east-2.amazonaws.com/dev";

// Local testing
if (window.location.hostname === "localhost") {
  apiUrl = "http://localhost:3030";
  console.log("Api url is pointing at localhost.");
}

console.log(`Env: ${isProd ? "Production" : "Development"} `);
console.log(`ApiUrl: ${apiUrl}`);

export const apiBaseUrl = apiUrl;

export const apiKey = "e1J0SBKqlM305CSXcp7yP5j00LG3s04xaz2AsTXl";
export const defaultFrom = "SJK ENTERPRISES LLC\n9925 N ALPINE RD\nMACHESNEY PARK, IL 61115\nUnited States";

export const batchSheetyUrl = "https://api.sheety.co/3f524be040ca6e58130988688cda2846/fmsBatchLabelInformation/batchLabelInformation";
export const productSheetyUrl = "https://api.sheety.co/3f524be040ca6e58130988688cda2846/fmsBatchLabelInformation/itemInformation";
export const shipmentSheetyUrl = "https://api.sheety.co/3f524be040ca6e58130988688cda2846/outboundShipmentsFlowManagement/outboundShipments";
