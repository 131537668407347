import Card from "../../components/cards/Card";

import Loading from "../../components/Loading";
import { useState } from "react";
import ShipmentDialog from "../../components/modals/ShipmentDialog";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import ShipmentsTable from "./components/ShipmentsTable";
import { refreshShipments } from "../../../redux/slices/thunks/refresh-helper";

function ShipmentsPage() {
  const dispatch = useDispatch<AppDispatch>();

  const shipments = useSelector((x: RootState) => x.data.shipments);
  const isLoading = useSelector((x: RootState) => x.data.isLoading);
  const info = useSelector((x: RootState) => x.data.shipmentsInfo);
  const canPullGoogleSheet = useSelector((x: RootState) => x.data.shipmentsCanPullGoogleSheet);
  const lastUpdatedTime = useSelector((x: RootState) => x.data.shipmentsLastUpdatedTime);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);

  return (
    <div className="flex w-full">
      <Card headerText={`Shipment Data`}>
        <div className="flex gap-4">
          <button
            className="mb-3 base-blue-button"
            onClick={() => {
              setSelectedShipment(null);
              setIsModalOpen(true);
            }}
          >
            Add new shipment
          </button>
          <button
            disabled={!canPullGoogleSheet}
            className={(!canPullGoogleSheet ? "disabled cursor-not-allowed" : "") + " base-blue-button mb-3"}
            onClick={() => dispatch(refreshShipments())}
          >
            Refresh Shipments from Google Sheet
          </button>
          <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
            <span className="font-medium">{info}</span>
          </div>
          <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
            <span className="font-medium">
              <>Last refreshed time (CST): {lastUpdatedTime}</>
            </span>
          </div>
        </div>
        {isLoading && <Loading />}
        {shipments && <ShipmentsTable setSelectedShipment={setSelectedShipment} setIsModalOpen={setIsModalOpen} />}
        {isModalOpen && <ShipmentDialog shipment={selectedShipment} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />}
      </Card>
    </div>
  );
}

export default ShipmentsPage;
