import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Product } from "../../data";
import SInput from "../form/SInput";
import { saveItem } from "../../../redux/api/app-api";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { refreshBatchesAndProducts } from "../../../redux/slices/thunks/refresh-helper";

type dialogType = {
  isOpen: boolean;
  setIsOpen: Function;
  product: Product | undefined | null;
  closeModalCallback?: Function;
};

const ItemDialog = ({ isOpen, setIsOpen, product, closeModalCallback }: dialogType) => {
  const dispatch = useAppDispatch();
  const items = useSelector((x: RootState) => x.data.products);
  const closeModal = () => {
    setIsOpen(false);
  };

  const [selectedItem, setSelectedItem] = useState<Product | null | undefined>(null);
  const [canClick, setCanClick] = useState(true);

  useEffect(() => {
    setSelectedItem(
      product ?? {
        product_id: "",
        sku: "",
        condition: "NEW ITEM",
        fn_sku: "",
        image: "",
        name: "",
        asin: "",
      }
    );
  }, [product]);

  const saveOrUpdate = async () => {
    if (selectedItem) {
      if (selectedItem.sku.trim().toLocaleLowerCase() === "" || selectedItem.name.trim().toLowerCase() === "") {
        toast.warn("Invalid inputs");
        return;
      }

      // Check if already exists
      if (!product) {
        const found = items?.find((x) => x.sku.trim().toLowerCase() === selectedItem.sku.trim().toLowerCase());
        if (found) {
          toast.warn("You can't create a new product with an already existed SKU");
          return;
        }
      }

      await saveItem({
        product_id: selectedItem.sku,
        sku: selectedItem.sku,
        condition: selectedItem.condition,
        fn_sku: selectedItem.fn_sku,
        image: selectedItem.image,
        name: selectedItem.name,
        asin: selectedItem.asin,
      });

      setCanClick(false);
      toast.info(`${!product ? "Adding" : "Updating"} product`);
      await dispatch(refreshBatchesAndProducts());
      toast.success(`${!product ? "Added" : "Updated"} product`);
      setCanClick(true);

      if (closeModalCallback) closeModalCallback();

      closeModal();
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" static onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex justify-center h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-1/2 p-6 overflow-hidden text-left align-middle transition-all transform shadow-xl h-fit rounded-2xl base-card">
                <div className="my-4">
                  <div className="mb-3 border-b-2">
                    <p className="base-p base-heading">{!product ? "Add new product" : "Edit product"}</p>
                  </div>
                  {selectedItem && (
                    <div className="flex flex-col gap-3">
                      <SInput
                        name="SKU"
                        value={selectedItem.sku}
                        disabled={product != null}
                        onChange={(val: any) => {
                          if (!product)
                            setSelectedItem({
                              ...selectedItem,
                              sku: val,
                            });
                        }}
                      />
                      <SInput
                        name="FNSKU"
                        value={selectedItem.fn_sku}
                        onChange={(val: any) => {
                          setSelectedItem({
                            ...selectedItem,
                            fn_sku: val,
                          });
                        }}
                      />
                      <SInput
                        name="Item Name"
                        value={selectedItem.name}
                        onChange={(val: any) => {
                          setSelectedItem({
                            ...selectedItem,
                            name: val,
                          });
                        }}
                      />
                      <SInput
                        name="Condition"
                        value={selectedItem.condition}
                        onChange={(val: any) => {
                          setSelectedItem({
                            ...selectedItem,
                            condition: val,
                          });
                        }}
                      />

                      <div className="flex justify-end gap-3">
                        <button disabled={!canClick} className="base-blue-button" onClick={saveOrUpdate}>
                          {!product ? "Save" : "Update"}
                        </button>
                        <button
                          disabled={!canClick}
                          className="base-gray-button"
                          onClick={() => {
                            if (closeModalCallback) closeModalCallback();
                            closeModal();
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ItemDialog;
