import React, { useState } from 'react';
import { Shipment } from '../../data';

type SInputType = {
  name: string,
  value: string | number | undefined | null,
  onChange?: any,
  shipments: Shipment[]
}

function ShipmentDropdown({ name, value, onChange, shipments }: SInputType) {
  return (
    <div className="w-full">
      <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{name}</label>
      <select value={value ?? "N/A"} onChange={(e) => onChange(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="N/A">Choose a shipment</option>
        {
          shipments.map(x => (
            <option key={x.shipment_id} value={x.shipment_id}>{x.shipment_name}</option>
          ))
        }
      </select>
    </div>
  );
}

export default ShipmentDropdown;
