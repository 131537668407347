import useGeneratorForm from "../components/form/box-label-and-production-hold/useGeneratorForm";

function ProductionHoldPage() {
  const { form } = useGeneratorForm({
    title: "Production Hold Generator",
    apiUrl: "production-hold-label",
    printText: "Print label",
    reprintText: "Reprint",
    isProductionHold: true,
  });

  return <>{form}</>;
}

export default ProductionHoldPage;
