/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "labelAppApi",
            "endpoint": "https://5ydziz503a.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:46fc1d4b-86b6-4b3a-ae79-446863976d58",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_RlZot7ATu",
    "aws_user_pools_web_client_id": "3dmnegsdes8qkpnp3bq0d98kps",
    "oauth": {
        "domain": "lbjkl4a5xjs0-prod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://prod.d2vag8l33rxtc7.amplifyapp.com/,http://localhost:3000/,https://www.sjkenterprisesapps.com/",
        "redirectSignOut": "https://prod.d2vag8l33rxtc7.amplifyapp.com/,http://localhost:3000/,https://www.sjkenterprisesapps.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "label-app-hosting-bucket-prod-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3uf4lfdd22vp.cloudfront.net"
};


export default awsmobile;
