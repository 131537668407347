import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';

import Card from '../../components/cards/Card';
import SInput from '../../components/form/SInput';
import Loading from '../../components/Loading';
import { Product } from '../../data';
import PDFDialog from '../../components/modals/PDFDialog';
import { useSearchParams } from 'react-router-dom';
import usePdfDialogHook from '../../../redux/hooks/usePdfDialogHook';
import PrintCardsContainer from '../../components/cards/PrintCardsContainer';
import PageHeader from '../../components/page/PageHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

// Similar to the FnSkuLabelPage, this doesn't check the database for input
function MfFnSkuLabelPage() {
  const { canGenerate, isPDFDialogOpen, setIsPDFDialogOpen, pdfBase64, print } = usePdfDialogHook();
  const [searchParams] = useSearchParams();

  const isInitialLoad = useSelector((x: RootState) => x.data.isInitialLoad);
  const isItemsLoading = useSelector((x: RootState) => x.data.isLoading);

  const urlQuery = {
    batchID: searchParams.get('batchID'),
    expirationDate: searchParams.get('expiration'),
    qtyToPrint: searchParams.get('qty') ?? '1',
    fnSku: searchParams.get('fnsku'),
    sku: searchParams.get('sku'),
    name: searchParams.get('name'),
    image: searchParams.get('image') ?? 'https://placehold.co/200x200.png?text=MF_label'
  };

  const [productSku, setProductSku] = useState<string>(urlQuery.sku ?? '');
  const [productFNSku, setProductFNSku] = useState<string>(urlQuery.fnSku ?? '');
  const [productExpiration, setProductExpiration] = useState<Date | null>(null);
  const [printQty, setPrintQty] = useState<string>(urlQuery.qtyToPrint);

  const placeHolderProduct: Product = {
    name: urlQuery.name ?? '',
    sku: productSku,
    fn_sku: productFNSku,
    condition: 'NEW ITEM',
    asin: '',
    image: urlQuery.image
  };

  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/mf?batchID=123BatchID&sku=SkuGoesHere&fnsku=FnSkuGoesHere&expiration=2-11-2034&qty=4&name=TEST ITEM&image=https://images-na.ssl-images-amazon.com/images/I/51WjhRi72wL.jpg
  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/mf?batchID=123BatchID&sku=SkuGoesHere&fnsku=FnSkuGoesHere&name=TEST ITEM
  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/mf?batchID=123BatchID&sku=SkuGoesHere&fnsku=FnSkuGoesHere

  useEffect(() => {
    if (urlQuery.expirationDate != null) {
      const expiratedDate = moment(urlQuery.expirationDate);
      if (expiratedDate.isValid()) {
        setProductExpiration(expiratedDate.toDate());
      }
    }
  }, []);

  const printWarningLabel3x2 = async () => {
    if (placeHolderProduct && productFNSku && printQty) {
      await print({
        apiUrl: 'product-warning-label3x2',
        data: {
          fnSku: productFNSku,
          productSku: productSku,
          productName: placeHolderProduct.name,
          productCondition: placeHolderProduct.condition,
          expirationDate: productExpiration == null ? '' : moment(productExpiration).format('L'),
          printQty: printQty,
          preview: false,
          batchID: urlQuery.batchID
        }
      });
    } else toast.warn('Missing inputs');
  };
  const printWarningLabel2x1 = async () => {
    if (placeHolderProduct && productFNSku && printQty) {
      await print({
        apiUrl: 'product-warning-label2x1',
        data: {
          fnSku: productFNSku,
          productSku: productSku,
          productName: placeHolderProduct.name,
          productCondition: placeHolderProduct.condition,
          expirationDate: productExpiration == null ? '' : moment(productExpiration).format('L'),
          printQty: printQty,
          batchID: urlQuery.batchID
        }
      });
    } else toast.warn('Missing inputs');
  };
  const printMFProductLabel = async () => {
    if (placeHolderProduct && productFNSku && printQty) {
      await print({
        apiUrl: 'product-mf-label2x1',
        data: {
          fnSku: productFNSku,
          productSku: productSku,
          productName: placeHolderProduct.name,
          productCondition: placeHolderProduct.condition,
          expirationDate: productExpiration == null ? '' : moment(productExpiration).format('L'),
          printQty: printQty,
          batchID: urlQuery.batchID
        }
      });
    } else toast.warn('Missing inputs');
  };
  const clearSelectedItem = () => {
    setProductSku('');
    setProductFNSku('');
    setPrintQty('');
    setProductExpiration(null);
  };

  return (
    <div className="flex flex-col w-full">
      <PageHeader headingText="MF FNSKU/Product Labels" />
      {isItemsLoading && <Loading />}
      {!isInitialLoad && (
        <>
          <PrintCardsContainer
            leftCard={
              <Card headerText="Product Information">
                <div className="flex flex-wrap">
                  <div className="flex flex-col w-3/5 gap-3 mt-3">
                    <SInput name="SKU" value={productSku} onChange={setProductSku} />
                    <SInput name="FNSKU" value={productFNSku} onChange={setProductFNSku} />
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Expiration Date
                      </label>
                      <DatePicker
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        selected={productExpiration}
                        onChange={(date: Date) => setProductExpiration(date)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-center w-2/5">
                    <div className="flex flex-col px-3 mt-5">
                      <h6 className="flex font-bold text dark:text-white">
                        {placeHolderProduct.fn_sku}
                      </h6>
                      <h6 className="flex text dark:text-white">{placeHolderProduct.name}</h6>
                      <div className="flex items-center justify-center w-full mt-2 border rounded max-h-96 dark:bg-gray-700">
                        {placeHolderProduct?.image ? (
                          <img
                            alt="product"
                            src={placeHolderProduct.image}
                            className="w-auto h-auto max-h-96"
                          />
                        ) : (
                          <svg
                            className="w-full h-48 p-5 text-gray-200"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            }
            rightCard={
              <Card headerText="Print">
                <div className="flex flex-wrap gap-5">
                  <button
                    disabled={!canGenerate}
                    onClick={printWarningLabel3x2}
                    className="w-full base-green-button"
                  >
                    Poly Bag W/ Suff Warning (3x2)
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={printWarningLabel2x1}
                    className="w-full base-green-button"
                  >
                    All Products W/O Suff Warning (2-¼” x 1-¼”)
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={printMFProductLabel}
                    className="w-full base-gray-button"
                  >
                    MF Product Label (3x2)
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={clearSelectedItem}
                    className="w-full base-yellow-button"
                  >
                    Clear All
                  </button>
                </div>
              </Card>
            }
          />
          <Card headerText="Select QTY to print">
            <div className="flex flex-row flex-wrap gap-2 mt-5">
              <div className="flex flex-wrap gap-1">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 16, 18, 20, 24, 36, 48, 72, 96].map((x) => (
                  <button
                    className="base-gray-button"
                    key={`printQty_` + x}
                    onClick={() => {
                      setPrintQty(x.toString());
                    }}
                  >
                    {x}
                  </button>
                ))}
              </div>
              <SInput name="" value={printQty} onChange={setPrintQty}></SInput>
            </div>
          </Card>
          <PDFDialog isOpen={isPDFDialogOpen} setIsOpen={setIsPDFDialogOpen} pdf={pdfBase64} />
        </>
      )}
    </div>
  );
}

export default MfFnSkuLabelPage;
