import React from "react";

type PrintCardsContainerType = {
  leftCard: React.ReactNode;
  rightCard: React.ReactNode;
};

export default function PrintCardsContainer(props: PrintCardsContainerType) {
  return (
    <div className="grid grid-cols-8 gap-2 mb-5">
      <div className={`flex col-span-6`}>{props.leftCard}</div>
      <div className="flex flex-col col-span-2">{props.rightCard}</div>
    </div>
  );
}
