import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Shipment } from "../../data";
import SInput from "../form/SInput";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { saveShipment } from "../../../redux/api/app-api";
import { toast } from "react-toastify";
import moment from "moment";
import { defaultFrom } from "../../options";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { refreshShipments } from "../../../redux/slices/thunks/refresh-helper";

type dialogType = {
  isOpen: boolean;
  setIsOpen: Function;
  shipment: Shipment | undefined | null;
  closeModalCallback?: Function;
};

type statusType = {
  name: string;
  value: boolean;
};

const ShipmentDialog = ({ isOpen, setIsOpen, shipment, closeModalCallback }: dialogType) => {
  const dispatch = useDispatch<AppDispatch>();
  const shipments = useSelector((x: RootState) => x.data.shipments);

  const closeModal = () => {
    setIsOpen(false);
  };

  const [id, setID] = useState("");
  const [packingListID, setPackingListID] = useState("");
  const [amazonRefID, setAmazonRefID] = useState("");
  const [destFC, setDestFC] = useState("");
  const [canClick, setCanClick] = useState(true);

  useEffect(() => {
    if (shipment) {
      setID(shipment.shipment_id);
      setPackingListID(shipment.packing_list_number);
      setAmazonRefID(shipment.amazon_reference_id);
      setDestFC(shipment.destination_fc);

      if (shipment.is_active) setStatus(statuses[0]);
      else setStatus(statuses[1]);
    } else {
      setID("");
      setPackingListID("");
      setAmazonRefID("");
      setDestFC("");
      setStatus(statuses[0]);
    }
    setCanClick(true);
  }, [shipment]);

  const statuses: statusType[] = [
    { name: "Active", value: true },
    { name: "Not Active", value: false },
  ];
  const [status, setStatus] = useState(statuses[0]);

  const saveOrUpdateShipment = async () => {
    if (id.trim().toLocaleLowerCase() === "" || packingListID.trim().toLowerCase() === "") {
      toast.warn("Invalid inputs");
      return;
    }

    // Check if shipment id exists
    if (!shipment) {
      const foundShipment = shipments?.find((x) => x.shipment_id.trim().toLowerCase() === id.trim().toLowerCase());
      if (foundShipment) {
        toast.warn("You can't create a new shipment with an already existed shipment ID");
        return;
      }
    }

    await saveShipment({
      shipment_id: id,
      packing_list_number: packingListID,
      shipment_name: "PackingList_" + packingListID,
      amazon_reference_id: amazonRefID,
      destination_fc: destFC,
      from_address: defaultFrom,
      destination_address: `FBA SJK Enterprises LLC\n${destFC}\n250 EMERALD DR\nJoliet, IL 60433-3280\nUnited States`,
      destination_address_basic: destFC,
      created_date: moment().format("L"),
      is_active: status.value === true ? 1 : 0,
    });

    setCanClick(false);
    toast.info(`${!shipment ? "Adding" : "Updating"} shipment`);
    await dispatch(refreshShipments());
    toast.success(`${!shipment ? "Added" : "Updated"} shipment`);
    setCanClick(true);

    if (closeModalCallback) closeModalCallback();

    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" static onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex justify-center h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-1/2 p-6 overflow-hidden text-left align-middle transition-all transform shadow-xl base-card h-fit rounded-2xl">
                <div className="my-4">
                  <div className="mb-3 border-b-2">
                    <p className="base-heading">{!shipment ? "Add new shipment" : "Edit shipment"}</p>
                  </div>

                  <div className="flex flex-col gap-3">
                    <SInput name="PACKING LIST NO." value={packingListID} onChange={setPackingListID} />
                    <SInput name="Shipment ID" disabled={shipment ? true : false} value={id} onChange={setID} />
                    <SInput name="Amazon Ref. Id" value={amazonRefID} onChange={setAmazonRefID} />
                    <SInput name="Dest. FC" value={destFC} onChange={setDestFC} />

                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Status</label>
                      <Listbox value={status} onChange={setStatus}>
                        <div className="relative mt-1">
                          <Listbox.Button className="relative w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light">
                            <span className="block truncate">{status.name}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>
                          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg base-card max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {statuses.map((s, i) => (
                                <Listbox.Option
                                  key={i}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-amber-100 text-amber-900" : "text-gray-900"}`
                                  }
                                  value={s}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{s.name}</span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>

                    <div className="flex justify-end gap-3">
                      <button disabled={!canClick} className="base-blue-button" onClick={saveOrUpdateShipment}>
                        {!shipment ? "Save" : "Update"}
                      </button>
                      <button
                        disabled={!canClick}
                        className="base-gray-button"
                        onClick={() => {
                          if (closeModalCallback) closeModalCallback();
                          closeModal();
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShipmentDialog;
