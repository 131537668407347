import React from "react";
import OutboundAndPalletProPage from "./common/outbound-and-pallet-pro/OutboundAndPalletProPage";

function PalletProCarrierLabelPage() {
  return (
    <OutboundAndPalletProPage apiUrl="pallet-pro-carrier-label" headingText="Pallet Pro Carrier Label Generator" allowToAddPallet={false} />
  );
}

export default PalletProCarrierLabelPage;
