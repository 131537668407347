import React, { useState } from "react";

type SInputType = {
  name: string;
  value: string | number | undefined | null;
  onChange?: any;
  disabled?: boolean;
  type?: string;
};

function SInput({ name, value, onChange, disabled, type }: SInputType) {
  return (
    <div className="w-full">
      <label htmlFor={name} className="block mb-2 text-sm font-medium base-p">
        {name}
      </label>
      <input
        type={type ? type : "text"}
        id={name}
        value={value ?? ""}
        min={1}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        disabled={disabled}
        className={
          (disabled ? "cursor-not-allowed" : "") +
          " shadow-sm bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 base-p dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
        }
      />
    </div>
  );
}

export default SInput;
