import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
  name: string;
  picture: string;
}

export interface InitState {
  loggedInUser: User | null;
}

const initialState: InitState = {
  loggedInUser: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User | null>) {
      state.loggedInUser = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
