// Could make this better
export const areBatchesEqual = (foundBatchFromDb: any, batch: any): boolean => {
  const propsToCheck = [
    "assigned_pl",
    "shipment_id",
    "shipment_destination_fc",
    "shipment_fc_address",
    "shipment_name",
    "product_id",
    "product_sku",
    "product_name",
    "product_image",
    "product_fnsku",
    "product_asin",
    "expiration_date",
    "production_quantity",
    "quantity_per_box",
    "identical_boxes",
    "print_fnsku_label",
  ];

  let areEqual = true;

  propsToCheck.forEach((prop) => {
    if (foundBatchFromDb[prop] != batch[prop]) {
      log(prop, foundBatchFromDb[prop], batch[prop]);
      areEqual = false;
    }
  });

  return areEqual;
};

const log = (propName: string, fromValue: any, toValue: any) => {
  console.log(`${propName} ${fromValue} is not equal to ${toValue}`);
};
