import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';

import Card from '../../components/cards/Card';
import SInput from '../../components/form/SInput';
import Loading from '../../components/Loading';
import { Product } from '../../data';
import PDFDialog from '../../components/modals/PDFDialog';
import { useParams } from 'react-router-dom';
import usePdfDialogHook from '../../../redux/hooks/usePdfDialogHook';
import PrintCardsContainer from '../../components/cards/PrintCardsContainer';
import PageHeader from '../../components/page/PageHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

function FnSkuLabelPage() {
  const { canGenerate, isPDFDialogOpen, setIsPDFDialogOpen, pdfBase64, print } = usePdfDialogHook();

  const {
    batchidOrSku: urlBatchidOrSku,
    expirationDate: urlExpirationDate,
    qtyToPrint: urlQtyToPrint
  } = useParams();

  const isInitialLoad = useSelector((x: RootState) => x.data.isInitialLoad);
  const isItemsLoading = useSelector((x: RootState) => x.data.isLoading);
  const products = useSelector((x: RootState) => x.data.products);
  const batches = useSelector((x: RootState) => x.data.batches);

  const [selectedProduct, setselectedProduct] = useState<Product | undefined | null>(null);
  const [productSku, setProductSku] = useState<string>('');
  const [productFNSku, setProductFNSku] = useState<string>('');
  const [productExpiration, setProductExpiration] = useState<Date | null>(null);
  const [printQty, setPrintQty] = useState<string>('');
  const [foundBatchFromUrl, setFoundBatchFromUrl] = useState<boolean>(false);

  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/14aca87b/3
  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/14aca87b/
  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/14aca87b/01-03-2026/3
  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/0a564f90/ab-07-bbbb
  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/2975/01-07-2031
  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/2975/01-07-2031/4
  // https://dev.d2vag8l33rxtc7.amplifyapp.com/fnsku-product-label/2975/01-07-abad/2

  useEffect(() => {
    if (batches.length > 0 && !isInitialLoad) {
      const init = () => {
        const foundBatch = batches.find((x) => x.batch_id === urlBatchidOrSku);
        const initQtyToPrint =
          urlQtyToPrint && !isNaN(parseInt(urlQtyToPrint))
            ? parseInt(urlQtyToPrint).toString()
            : null;

        let initExpirationDate = null;
        if (urlExpirationDate && moment(urlExpirationDate).isValid()) {
          initExpirationDate = moment(urlExpirationDate).toDate();
          console.log(initExpirationDate);
        }

        // Try to look up as a batch id
        if (foundBatch != null) {
          const foundProduct = products.find((x) => x.sku === foundBatch?.product_sku);
          if (foundBatch && foundProduct) {
            setFoundBatchFromUrl(true);
            setselectedProduct(foundProduct);
            setProductSku(foundBatch.product_sku);
            setProductFNSku(foundBatch.product_fnsku);

            if (initExpirationDate !== null) {
              setProductExpiration(initExpirationDate);
            } else if (foundBatch.expiration_date && moment(foundBatch.expiration_date).isValid()) {
              setProductExpiration(moment(foundBatch.expiration_date).toDate());
            }
            // If there is a init qty, use that, if not, check batch print qty, if null, set it to "1"
            setPrintQty(
              initQtyToPrint ??
                (foundBatch.production_quantity != null
                  ? foundBatch.production_quantity.toString()
                  : '1')
            );
          }
        } else {
          // Try to look up as a sku
          const foundProduct = products.find(
            (x) =>
              x.sku.toLowerCase().trim() === urlBatchidOrSku?.toLowerCase().trim() ||
              x.fn_sku.toLowerCase().trim() === urlBatchidOrSku?.toLowerCase().trim()
          );
          if (foundProduct != null) {
            // param is a sku or fn_sku
            setselectedProduct(foundProduct);
            setProductSku(foundProduct.sku);
            setProductFNSku(foundProduct.fn_sku);
            if (initExpirationDate !== null) {
              setProductExpiration(initExpirationDate);
            } else {
              setProductExpiration(null);
            }
            setPrintQty(initQtyToPrint ?? '1');
          }
        }
      };

      if (urlBatchidOrSku) init();
      else {
        clearSelectedItem();
      }
    }
  }, [isInitialLoad]);

  const doItemChange = (value: any) => {
    setProductSku(value);
    if (value && value.trim() !== '') {
      // Get item
      const foundItem = products?.find(
        (x) => x.sku.trim().toLocaleLowerCase() === value.trim().toLowerCase()
      );
      setselectedProduct(foundItem);
      if (foundItem) {
        setProductFNSku(foundItem.fn_sku);
      } else {
        setProductFNSku('');
        setPrintQty('');
        setProductExpiration(null);
      }
    }
  };
  const printWarningLabel3x2 = async () => {
    if (selectedProduct && productFNSku && printQty) {
      await print({
        apiUrl: 'product-warning-label3x2',
        data: {
          fnSku: productFNSku,
          productSku: productSku,
          productName: selectedProduct.name,
          productCondition: selectedProduct.condition,
          expirationDate: productExpiration == null ? '' : moment(productExpiration).format('L'),
          printQty: printQty,
          preview: false,
          batchID: foundBatchFromUrl ? urlBatchidOrSku : null
        }
      });
    } else toast.warn('Missing inputs');
  };
  const printWarningLabel2x1 = async () => {
    if (selectedProduct && productFNSku && printQty) {
      await print({
        apiUrl: 'product-warning-label2x1',
        data: {
          fnSku: productFNSku,
          productSku: productSku,
          productName: selectedProduct.name,
          productCondition: selectedProduct.condition,
          expirationDate: productExpiration == null ? '' : moment(productExpiration).format('L'),
          printQty: printQty,
          batchID: foundBatchFromUrl ? urlBatchidOrSku : null
        }
      });
    } else toast.warn('Missing inputs');
  };
  const printMFProductLabel = async () => {
    if (selectedProduct && productFNSku && printQty) {
      await print({
        apiUrl: 'product-mf-label2x1',
        data: {
          fnSku: productFNSku,
          productSku: productSku,
          productName: selectedProduct.name,
          productCondition: selectedProduct.condition,
          expirationDate: productExpiration == null ? '' : moment(productExpiration).format('L'),
          printQty: printQty,
          batchID: foundBatchFromUrl ? urlBatchidOrSku : null
        }
      });
    } else toast.warn('Missing inputs');
  };
  const clearSelectedItem = () => {
    setProductSku('');
    setProductFNSku('');
    setPrintQty('');
    setProductExpiration(null);
    setselectedProduct(null);
  };

  return (
    <div className="flex flex-col w-full">
      <PageHeader headingText="FNSKU/Product Labels" />
      {isItemsLoading && <Loading />}
      {products && !isInitialLoad && (
        <>
          <PrintCardsContainer
            leftCard={
              <Card headerText="Product Information">
                <div className="flex flex-wrap">
                  <div className="flex flex-col w-3/5 gap-3 mt-3">
                    <SInput name="SKU" value={productSku} onChange={doItemChange} />
                    <SInput name="FNSKU" value={productFNSku} onChange={setProductFNSku} />
                    <div className="w-full">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Expiration Date
                      </label>
                      <DatePicker
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        selected={productExpiration}
                        onChange={(date: Date) => setProductExpiration(date)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap items-center justify-center w-2/5">
                    <div className="flex flex-col px-3 mt-5">
                      <h6 className="flex font-bold text dark:text-white">
                        {selectedProduct ? selectedProduct.fn_sku : ''}
                      </h6>
                      <h6 className="flex text dark:text-white">
                        {selectedProduct ? selectedProduct.name : ''}
                      </h6>
                      <div className="flex items-center justify-center w-full border rounded max-h-96 dark:bg-gray-700">
                        {selectedProduct?.image ? (
                          <img
                            alt="product"
                            src={selectedProduct.image}
                            className="w-auto h-auto mt-2 max-h-96"
                          />
                        ) : (
                          <svg
                            className="w-full h-48 p-5 text-gray-200"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                          >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                          </svg>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            }
            rightCard={
              <Card headerText="Print">
                <div className="flex flex-wrap gap-5">
                  <button
                    disabled={!canGenerate}
                    onClick={printWarningLabel3x2}
                    className="w-full base-green-button"
                  >
                    Poly Bag W/ Suff Warning (3x2)
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={printWarningLabel2x1}
                    className="w-full base-green-button"
                  >
                    All Products W/O Suff Warning (2-¼” x 1-¼”)
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={printMFProductLabel}
                    className="w-full base-gray-button"
                  >
                    MF Product Label (3x2)
                  </button>
                  <button
                    disabled={!canGenerate}
                    onClick={clearSelectedItem}
                    className="w-full base-yellow-button"
                  >
                    Clear All
                  </button>
                </div>
              </Card>
            }
          />
          <Card headerText="Select QTY to print">
            <div className="flex flex-row flex-wrap gap-2 mt-5">
              <div className="flex flex-wrap gap-1">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 16, 18, 20, 24, 36, 48, 72, 96].map((x) => (
                  <button
                    className="base-gray-button"
                    key={`printQty_` + x}
                    onClick={() => {
                      setPrintQty(x.toString());
                    }}
                  >
                    {x}
                  </button>
                ))}
              </div>
              <SInput name="" value={printQty} onChange={setPrintQty}></SInput>
            </div>
          </Card>
          <PDFDialog isOpen={isPDFDialogOpen} setIsOpen={setIsPDFDialogOpen} pdf={pdfBase64} />
        </>
      )}
    </div>
  );
}

export default FnSkuLabelPage;
